// @flow

import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import BusinessFocusView from "../components/business.focus.view";
import { BusinessFocusStyle } from "../styles/business.station.style";

export type Props = {
  classes: { [key: string]: string },
};
export type State = {};
class BusinessFocus extends Component<Props, State> {
  componentDidMount() {
    console.log("isMounted");
  }

  render() {
    return <BusinessFocusView {...this.props} />;
  }
}
export default withStyles(BusinessFocusStyle)(BusinessFocus);
