// @flow
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import type { Location, RouterHistory } from "react-router-dom";
import { connect } from "react-redux";
import { closeSession } from "core/users/actions/session.action";
import DashboardLayout from "../components/dashboard.layout";
import { dashStyle } from "../../styles/dashboard.layout.style";
import Routes from "../../../shared/routes.def";

export type Props = {
  classes: { [key: string]: string },
  location: Location,
  history: RouterHistory,
  closeSession: typeof closeSession,
};

export type State = {
  showDrawer: boolean,
  openModal: boolean,
};

class DashboardLayoutContainer extends Component<$Exact<Props>, $Exact<State>> {
  state = {
    showDrawer: true,
    openModal: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    const { location } = this.props;
    const { showDrawer } = this.state;
    if (location.pathname !== prevProps.location.pathname) {
      if (showDrawer) {
        this.toggleDrawer();
      }
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { location } = this.props;
    const { showDrawer } = this.state;
    if (location.pathname !== prevProps.location.pathname) {
      if (showDrawer) {
        this.toggleDrawer();
      }
    }
  };

  toggleDrawer = () => {
    this.setState(prevState => ({ showDrawer: !prevState.showDrawer }));
  };

  toggleModal = () => {
    this.setState((prevState: State) => ({ openModal: !prevState.openModal }));
  };

  logout = () => {
    const { closeSession, history } = this.props;
    closeSession(() => {
      console.log("replace");
      history.replace("/auth/sign-in");
    });
  };

  render() {
    const { location } = this.props;
    const adminView = location.pathname === Routes.ADMIN;
    return (
      <DashboardLayout
        adminView={adminView}
        toggleDrawer={this.toggleDrawer}
        logout={this.logout}
        toggleModal={this.toggleModal}
        {...this.props}
        {...this.state}
      />
    );
  }
}

const mapDispatchToProps = {
  closeSession,
};

const DashboardLayoutContainerRedux = connect(
  null,
  mapDispatchToProps,
)(DashboardLayoutContainer);

export default withStyles(dashStyle, { withTheme: true })(
  withRouter(DashboardLayoutContainerRedux),
);
