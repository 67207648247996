// @flow
import React from "react";
import { ToastContainer } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core";

const StyledToastContainer = ({
  classes,
}: {
  classes: { [key: string]: string },
}) => (
  <ToastContainer
    autoClose={3000000}
    position="bottom-center"
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    closeButton={(
      <IconButton>
        <CloseIcon className={classes.icon} />
      </IconButton>
    )}
    toastClassName={classes.toast}
    pauseOnHover
  />
);
export default withStyles({
  toast: { borderRadius: 10, paddingLeft: 15 },
  icon: { color: "#fff" },
})(StyledToastContainer);
