// @flow

import React, { Component } from "react";
import type { ComponentType } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import type { State as GlobalState } from "core/reducer";
import type { Location } from "react-router-dom";
import PageLoader from "../components/page.loader";
import Routes from "../routes.def";
import { initSession } from "../../../core/users/actions/session.action";
/**
 * High Order Component with provide an authentication check
 * If the user is loggedIn then he will be redirected to the login page
 *
 * @export
 * @param {ComponentType<*>} wrappedComponent
 * @returns
 */
export function withAuthentication(WrappedComponent: ComponentType<*>) {
  const mapDispatchToProps = {
    initSession,
  };

  const mapStateToProps = (state: GlobalState) => ({
    loggedIn: state.auth.loggedIn,
    upgradeRequired: state.auth.upgradeRequired,
  });

  type Props = {
    loggedIn: boolean,
    loading: boolean,
    location: Location,
    initSession: typeof initSession,
    classes: { [key: string]: string },
    upgradeRequired: boolean,
  };
  type State = { loading: boolean };
  const AuthHoc = class extends Component<Props, State> {
    state = {
      loading: true,
    };

    componentDidMount() {
      const { loggedIn, initSession } = this.props;
      if (!loggedIn) {
        initSession(() => {
          this.setState(() => ({ loading: false }));
        });
      } else {
        this.setState(() => ({ loading: false }));
      }
    }

    componentDidUpdate = (prevProps: Props) => {
      const { loggedIn } = this.props;
      if (loggedIn !== prevProps.loggedIn) {
        this.setState(() => ({ loading: false }));
      }
    };

    render() {
      const { loggedIn, location, upgradeRequired } = this.props;
      const { loading } = this.state;
      if (loggedIn === true && !loading && !upgradeRequired) {
        return <WrappedComponent />;
      }
      if (location.pathname !== Routes.AUTH && !loading && !upgradeRequired) {
        return <Redirect to={Routes.AUTH} />;
      }
      if (upgradeRequired) {
        return <Redirect to="/auth/password/update" />;
      }
      if (loading) {
        return <PageLoader loading />;
      }
      return null;
    }
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(AuthHoc));
}
