// @flow
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TotalLogo from "web/assets/images/total_logo.png";
import { authStyle } from "../styles/auth.style";
import AuthRouter from "../router";

type Props = {
  classes: { [key: string]: string }
};

const Authenticationlayout = (props: Props) => {
  const { classes } = props;
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={`${classes.fullHeight} ${classes.formContainer}`}
    >
      <Grid xs={12} item>
        <img className={`${classes.logo}`} alt="total-logo" src={TotalLogo} />
      </Grid>
      <Grid xs={12} lg={6} md={8} item>
        <Card className={`${classes.totalShadow}`}>{AuthRouter}</Card>
      </Grid>
    </Grid>
  );
};

export default withStyles(authStyle)(withRouter(Authenticationlayout));
