// @flow
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthenticationContainer from "./containers/auth.container";
import ActivateContainer from "./containers/activate.container";
import ForgottenPasswordContainer from "./containers/forgotten.password.container";
import UpdatePasswordContainer from "./containers/update.password.container";
import Routes from "../shared/routes.def";
import { withNoAuthentication } from "../shared/hoc/unauth.hoc";

export default (
  <Switch>
    <Route
      path={Routes.SIGN_IN}
      component={withNoAuthentication(AuthenticationContainer)}
    />
    <Route
      path={Routes.FIRST_CONNECT}
      component={withNoAuthentication(ActivateContainer)}
    />
    <Route
      path={Routes.PASSWORD_FORGOTTEN}
      component={withNoAuthentication(ForgottenPasswordContainer)}
    />
    <Route
      exact
      path={Routes.RESET_PASSWORD}
      component={UpdatePasswordContainer}
    />
    <Route
      exact
      path={Routes.RESET_PASSWORD_WITH_TOKEN}
      component={UpdatePasswordContainer}
    />
    <Redirect to={Routes.SIGN_IN} />
  </Switch>
);
