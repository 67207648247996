// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import Strings from "web/assets/strings";
import CustomTypography from "../../shared/components/custom.typography.component";

import type {
  State as ContainerState,
  Props as ContainerProps,
} from "../containers/kpi.progress.container";

type Props = ContainerState &
  ContainerProps & {
    value: string,
    caption: string,
  };
const KPIProgressView = ({ classes, caption, value }: Props) => (
  <Grid item>
    <Grid container item direction="row" justify="center" alignItems="baseline">
      <CustomTypography size={15.5}>
        {Strings.dashboard.customers}
      </CustomTypography>
      <CustomTypography size={15.5} className={classes.caption} bold>
        {" "}
        {caption}
      </CustomTypography>
    </Grid>
    <Grid item>
      <CustomTypography bold size={22} className={classes.value}>
        {value}
        {" "}
        <Tooltip title="Hey this an awesome tooltip here">
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </CustomTypography>
    </Grid>
    <Grid item xs={12}>
      <div className={classes.progress}>
        <div className={classes.innerProgress} />
      </div>
    </Grid>
  </Grid>
);
export default KPIProgressView;
