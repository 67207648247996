// @flow
import React from "react";
import type { FieldProps } from "redux-form";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import type { Node } from "react";

const styles = theme => ({
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
    width: "100%",
  },
  bootstrapInput: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "0.5px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto", "\"Helvetica Neue\""].join(","),
    boxShadow: "0px 0px 1px 0.5px rgba(0, 0, 0, 0.3) inset",
  },
  icon: {
    backgroundColor: "#333333",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  container: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  fullHeight: {
    height: "100%",
  },
});

const RFTextField = ({
  input,
  label,
  classes,
  icon,
  placeholder,
  type,
  meta: { touched, error },
  ...custom
}: FieldProps & {
  label: string,
  placeholder: string,
  classes: { [key: string]: string },
  icon: Node,
  custom: any,
  type: string,
}) => (
  <FormControl className={classes.container}>
    <Grid container direction="row">
      <Grid item xs={1} className={classes.icon}>
        <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
          {icon}
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <InputBase
          label={label}
          type={type}
          error={touched && error}
          classes={{
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
          }}
          placeholder={placeholder}
          {...custom.inputProps}
          {...input}
        />
      </Grid>
    </Grid>
  </FormControl>
);

export default withStyles(styles)(RFTextField);
