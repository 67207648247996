// @flow

export const FuelStationStyle = () => ({
  img: {
    height: "50px",
    marginBottom: "15px",
  },
  labels: {
    color: "#6f6f6e",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: 10,
  },
  redLabels: { color: "red", fontWeight: "bold" },
  greenLabels: { color: "#00c853", fontWeight: "bold" },
});
