// @flow

export default () => ({
  helpIcon: { color: "red", paddingBottom: 4 },
  caption: { marginLeft: 5 },
  progress: {
    height: 14,
    width: "9.36vw",
    minWidth: "100px",
    zIndex: 3,
    borderRadius: 7,
    boxShadow: "inset -1px -1.7px 2px 0 rgba(255, 140, 29, 0.9)",
    backgroundImage:
      "linear-gradient(90deg, rgba(111,111,110,1) 0%, rgba(228,2,45,0.5) 100%)",
    overflow: "hidden",
  },
  innerProgress: {
    borderBottomLeftRadius: 7,
    borderTopLeftRadius: 7,
    transform: "skew(-30deg)",
    paddingRight: 10,
    marginLeft: -15,
    width: "calc(50% + 15px)",
    zIndex: 1,
    height: 14,
    boxShadow: "0px 2px 5px 0 rgba(118, 0, 22, 0.8)",
    backgroundImage: "linear-gradient(to left, #e4022d, #9d2235)",
  },
  value: {
    fontSize: "22px",
    color: "#ec1a3b",
    lineHeight: 1.1,
  },
});
