// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "redux-form";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import LinkButton from "web/shared/components/link.component";
import Typography from "@material-ui/core/Typography";
import Strings from "web/assets/strings";
import { Link } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import LoadingButton from "../../shared/components/loading.button";
import type {
  Props as ContainerProps,
  State as ContainerState,
} from "../containers/activate.container";
import RFTextField from "./textfield.component";
import Routes from "../../shared/routes.def";

type Props = ContainerProps &
  ContainerState & {
    onFirstConnectClick: () => Promise<void>,
  };

export default (props: Props) => {
  const {
    classes, handleSubmit, onFirstConnectClick, loading,
  } = props;
  return (
    <Fade in>
      <form onSubmit={handleSubmit(onFirstConnectClick)} className={`${classes.fullHeight}`}>
        <Grid container className={`${classes.fieldsContainer}`} justify="center">
          <Grid item className={`${classes.title}`}>
            {/* $FlowFixMe */}
            <Typography component="h2" variant="h5">
              {Strings.activation.title}
            </Typography>
            <Typography variant="body2" className={`${classes.subtitle}`}>
              {Strings.activation.subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              className={`${classes.authField}`}
              name="igg"
              icon={<PersonIcon className={classes.iconColor} />}
              component={RFTextField}
              placeholder={Strings.auth.iggPlaceholder}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              className={`${classes.authField}`}
              name="email"
              icon={<EmailIcon className={classes.iconColor} />}
              component={RFTextField}
              placeholder={Strings.global.email}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              style={{ height: 100 }}
              direction="column"
              alignItems="flex-end"
              justify="flex-end"
            >
              <LinkButton variant="text" component="span" className={`${classes.buttonLink}`}>
                <Link className={`${classes.link}`} to={Routes.SIGN_IN}>
                  <Typography variant="caption" gutterBottom>
                    {Strings.auth.connect}
                  </Typography>
                </Link>
              </LinkButton>
            </Grid>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loading}
              type="submit"
              className={`${classes.connectButton}`}
              text={Strings.global.activate}
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};
