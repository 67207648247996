// @flow
import { toast } from "react-toastify";

export default class Toaster {
  toastId: ?number = null;

  /**
   * Notify user with a toast, if there is already a toast on board, update it
   *
   * @memberof Toaster
   */
  notify = (text: string, success: boolean) => {
    if (this.toastId != null && toast.isActive(this.toastId)) {
      toast.dismiss();
      setTimeout(() => {
        this.toastId = toast(text, {
          autoClose: 5000,
          type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
        });
      }, 300);
    } else {
      this.toastId = toast(text, {
        autoClose: 5000,
        type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      });
    }
  };

  onMessageReceived = (message: string, type: "ERROR" | "SUCCESS") => {
    this.notify(message, type === "SUCCESS");
  };
}
