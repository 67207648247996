// @flow
import React, { Component } from "react";
import { reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import type { State as GlobalState } from "core/reducer";
import { authRequest } from "core/authentication/actions/auth.action";
import { withStyles } from "@material-ui/core/styles";
import Strings from "web/assets/strings";
import type { FormProps, Form } from "../../shared/custom-types-definitions/redux-form";
import AuthenticationView from "../components/auth.view";
import { authStyle } from "../styles/auth.style";

export type Props = {
  +authRequest: typeof authRequest,
  +classes: { [key: string]: string },
  +loginForm: Form,
} & FormProps;

export type State = {|
  +showPassword: boolean,
  +loading: boolean,
|};

class AuthenticationContainer extends Component<Props, State> {
  state = {
    showPassword: false,
    loading: false,
  };

  togglePasswordVisibility = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  login = () => new Promise((resolve, reject) => {
    const { authRequest, loginForm } = this.props;
    if (loginForm.values) {
      this.setState(() => ({ loading: true }));
      authRequest(loginForm.values.IGG, loginForm.values.password, (error) => {
        if (error == null) {
          this.setState(() => ({ loading: false }));
          resolve();
        } else if (error.response) {
          switch (error.response.status) {
          case 401:
            reject(new SubmissionError({ _error: Strings.auth.wrongCredentials }));
            break;
          default:
          }
          this.setState(() => ({ loading: false }));
        } else {
          this.setState(() => ({ loading: false }));
          reject(new SubmissionError({ _error: error }));
        }
      });
    } else {
      reject(new SubmissionError({ _error: "EmptyFields" }));
    }
  });

  render() {
    const { loginForm, ...rest } = this.props;
    return (
      <AuthenticationView
        togglePasswordVisibility={this.togglePasswordVisibility}
        login={this.login}
        {...this.state}
        {...rest}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  auth: state.auth,
  loginForm: state.form.login,
});

const mapDispatchToProps = {
  authRequest,
};

// - Wrap with form HOC
const AuthenticationContainerForm = reduxForm({
  // a unique name for the form
  form: "login",
})(AuthenticationContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(authStyle)(AuthenticationContainerForm));
