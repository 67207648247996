// @flow
import { all } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watcherSaga from "./authentication/sagas/auth.saga";
import watcherActivation from "./authentication/sagas/activate.saga";
import {
  passwordResetWatcherSaga,
  passwordUpdateWatcherSaga,
  askPasswordResetWatcherSaga,
} from "./authentication/sagas/password.saga";
import watcherAccountRegistration from "./authentication/sagas/account.registration.saga";
import {
  sessionWatcherSaga,
  logoutWatcherSaga,
} from "./users/sagas/session.saga";
import {
  userWatcherSaga,
  getUsersWatcherSaga,
  createUserWatcherSaga,
  updateUserWatcherSaga,
} from "./users/sagas/user.saga";

export default function* rootSaga(): Saga<void> {
  // $FlowFixMe
  yield all([
    logoutWatcherSaga(),
    watcherSaga(),
    watcherActivation(),
    passwordResetWatcherSaga(),
    watcherAccountRegistration(),
    passwordUpdateWatcherSaga(),
    sessionWatcherSaga(),
    userWatcherSaga(),
    getUsersWatcherSaga(),
    askPasswordResetWatcherSaga(),
    createUserWatcherSaga(),
    updateUserWatcherSaga(),
  ]);
}
