// @flow
import { api } from "./api";
import type { User, PartialUser } from "../models/user.entity";

export default class UserApi {
  /**
   * Web services used to get current user
   *
   * @static
   * @memberof UserApi
   */
  static async getUserMe() {
    const response = await api.get("/users/me").catch((error) => {
      throw error;
    });
    return response.data;
  }

  /**
   * Edit a user
   *
   * @static
   * @param {string} id
   * @param {User} user
   * @returns {Promise<void>}
   * @memberof UserApi
   */
  static async updateUser(user: User): Promise<void> {
    const response = await api
      .put(`/users/${user.id}`, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        igg: user.igg,
        administrator: user.administrator,
        active: user.active,
      })
      .catch((error) => {
        throw error;
      });
    return response.data;
  }

  /**
   *Get users
   *
   * @static
   * @returns {Promise<Array<User>>}
   * @memberof UserApi
   */
  static async getUsers(
    search?: ?string,
    active?: ?boolean,
    administrator?: ?boolean,
    page?: ?number,
    itemsPerPage?: ?number,
  ): Promise<{ users: Array<User>, totalItems: number }> {
    const response = await api
      .get("/users.jsonld", {
        params: {
          user_fulltext: search !== "" ? search : null,
          active,
          administrator,
          page,
          itemsPerPage,
        },
      })
      .catch((error) => {
        throw error;
      });
    return {
      users: response.data["hydra:member"],
      totalItems: response.data["hydra:totalItems"],
    };
  }

  /**
   * Create a user
   *
   * @static
   * @param {User} user
   * @returns
   * @memberof UserApi
   */
  static async createUser(user: PartialUser) {
    const response = await api.post("/users", user).catch((err) => {
      throw err;
    });
    return response.data;
  }

  /**
   * Disconnect the user
   *
   * @static
   * @memberof UserApi
   */
  static async logout() {
    await api.get("logout").catch((err) => {
      throw err;
    });
  }
}
