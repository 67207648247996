// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import type { Saga, Effect } from "redux-saga";
import type {
  InitSessionAction,
  SessionLogout,
} from "../actions/session.action.types";
import { INIT_SESSION, SESSION_LOGOUT } from "../../actions/constants";
import {
  initSessionSucceeded,
  initSessionFailed,
  closeSessionFailed,
  closeSessionSucceeded,
} from "../actions/session.action";
import { userMeSaga } from "./user.saga";
import { checkPasswordValiditySaga } from "../../authentication/sagas/password.saga";
import { getUserMe } from "../actions/user.action";
import { checkPasswordValidity } from "../../authentication/actions/password.action";
import UserApi from "../../api/user.api";
/**
 * Handle session initialization process
 *
 * @param {InitSessionAction} action
 * @returns {Saga<void>}
 */
export function* initSessionSaga(action: InitSessionAction): Saga<void> {
  try {
    // - Get user me
    const user = yield call(userMeSaga, getUserMe(action.done));
    if (user != null) {
      // - User is not null, check password
      yield call(
        checkPasswordValiditySaga,
        checkPasswordValidity(user.id, action.done),
      );
    } else {
      throw new Error("User is null");
    }
    yield put(initSessionSucceeded());
  } catch (error) {
    yield put(initSessionFailed(error));
    action.done(error);
  }
}

/**
 * Watching for INIT_SESSION EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export function* sessionWatcherSaga(): Iterable<Effect> {
  yield takeLatest(INIT_SESSION, initSessionSaga);
}

/**
 * Handle session closing process
 *
 * @param {InitSessionAction} action
 * @returns {Saga<void>}
 */
export function* closeSessionSaga(action: SessionLogout): Saga<void> {
  try {
    // - Get user me
    yield call(UserApi.logout);
    yield put(closeSessionSucceeded());
    action.done();
  } catch (error) {
    yield put(closeSessionFailed(error));
    action.done();
  }
}

/**
 * Watching for SESSION_LOGOUT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export function* logoutWatcherSaga(): Iterable<Effect> {
  yield takeLatest(SESSION_LOGOUT, closeSessionSaga);
}
