// @flow
import type { $AxiosError } from "axios";
import {
  USER_ME_FAILED,
  USER_ME_REQUEST,
  USER_ME_SUCCESS,
  GET_USERS_FAILED,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  SET_FILTER_USERS,
  ADD_CUSTOM_FILTER,
  REMOVE_CUSTOM_FILTER,
  SET_FILTER_USERS_ENABLED,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS,
  CREATE_USER_REQUEST,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
} from "../../actions/constants";
import type { User, PartialUser } from "../../models/user.entity";

import type {
  UserMeSuccessAction,
  UserMeFailedAction,
  UserMeRequestAction,
  UsersFailedAction,
  UsersRequestAction,
  UsersSuccessAction,
  SetFilterAction,
  AddCustomFilterAction,
  SetFilterEnabledAction,
  RemoveCustomFilterAction,
  CreateUserFailedAction,
  CreateUserRequestAction,
  CreateUserSuccessAction,
  UpdateUserFailedAction,
  UpdateUserRequestAction,
  UpdateUserSuccessAction,
} from "./user.action.types";

export const getUserMe = (
  done: (error: ?$AxiosError<*, *>) => void,
): UserMeRequestAction => ({
  type: USER_ME_REQUEST,
  done,
});

export const userMeFailed = (error: $AxiosError<*, *>): UserMeFailedAction => ({
  type: USER_ME_FAILED,
  toast: false,
  error,
});

export const userMeSucceeded = (user: User): UserMeSuccessAction => ({
  type: USER_ME_SUCCESS,
  user,
  toast: true,
});

export const getUsers = (
  done: (error: ?$AxiosError<*, *>) => void,
  search?: ?string,
  active?: ?boolean,
  administrator?: ?boolean,
  page?: ?number,
  itemsPerPage?: ?number,
): UsersRequestAction => ({
  type: GET_USERS_REQUEST,
  done,
  search,
  active,
  administrator,
  page,
  itemsPerPage,
});

export const getUsersFailed = (
  error: $AxiosError<*, *>,
): UsersFailedAction => ({
  type: GET_USERS_FAILED,
  toast: true,
  error,
});

export const getUsersSucceeded = (
  users: { [key: string]: User },
  totalItems: number,
): UsersSuccessAction => ({
  type: GET_USERS_SUCCESS,
  users,
  totalItems,
});

export const setFilter = (filter: string): SetFilterAction => ({
  type: SET_FILTER_USERS,
  filter,
});

export function setCustomFilter<T>(
  key: string,
  value: T,
): AddCustomFilterAction<T> {
  const filter = {
    key,
    value,
  };
  return {
    type: ADD_CUSTOM_FILTER,
    filter,
  };
}

export function removeCustomFilter(key: string): RemoveCustomFilterAction {
  return {
    type: REMOVE_CUSTOM_FILTER,
    key,
  };
}
export const setFilterEnabled = (enabled: boolean): SetFilterEnabledAction => ({
  type: SET_FILTER_USERS_ENABLED,
  enabled,
});

export const createUser = (
  user: PartialUser,
  done: (error: ?$AxiosError<*, *>) => void,
): CreateUserRequestAction => ({
  type: CREATE_USER_REQUEST,
  user,
  done,
});

export const createUserFailed = (
  error: $AxiosError<*, *>,
): CreateUserFailedAction => ({
  type: CREATE_USER_FAILED,
  toast: true,
  error,
});

export const createUserSucceeded = (user: User): CreateUserSuccessAction => ({
  type: CREATE_USER_SUCCESS,
  toast: true,
  user,
});

export const updateUser = (
  user: User,
  done: (error: ?$AxiosError<*, *>) => void,
): UpdateUserRequestAction => ({
  type: UPDATE_USER_REQUEST,
  done,
  user,
});

export const updateUserFailed = (
  error: $AxiosError<*, *>,
): UpdateUserFailedAction => ({
  type: UPDATE_USER_FAILED,
  toast: true,
  error,
});

export const updateUserSucceeded = (user: User): UpdateUserSuccessAction => ({
  type: UPDATE_USER_SUCCESS,
  toast: true,
  user,
});
