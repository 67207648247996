// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import type { Saga, Effect } from "redux-saga";
import type { ActivationRequestAction } from "../actions/activate.action.types";
import { activationFailed, activationSucceeded } from "../actions/activate.action";
import AuthApi from "../../api/auth.api";
import { ACTIVATION_REQUEST } from "../../actions/constants";
/**
 * Handle auth process
 *
 * @param {AuthenticationRequestAction} action
 * @returns {Saga<void>}
 */
export function* activationSaga(action: ActivationRequestAction): Saga<void> {
  try {
    yield call(AuthApi.firstLogin, action.IGG, action.mail);
    yield put(activationSucceeded());
    // Trigger callback needed to return an error to reduxForm
    action.done();
  } catch (error) {
    yield put(activationFailed(error));
    // Trigger callback needed to return an error to reduxForm
    action.done(error);
  }
}

/**
 * Watching for ACTIVATION_REQUEST EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export default function* watcherActivation(): Iterable<Effect> {
  yield takeLatest(ACTIVATION_REQUEST, activationSaga);
}
