// @flow
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import KPIProgressView from "../components/kpi.progress.view";
import kpiProgressStyle from "../styles/kpi.progress.style";

export type Props = {
  classes: { [key: string]: string },
};
export type State = {};
class KPIProgressContainer extends Component<Props, State> {
  componentDidMount = () => {
    console.log("DidMount");
  };

  render() {
    return (
      <KPIProgressView
        {...this.props}
        caption="en très forte baisse"
        value="11 576 m³"
      />
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(kpiProgressStyle)(KPIProgressContainer));
