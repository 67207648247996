// @flow
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
// import type { State as GlobalState } from "core/reducer";
import KPIView from "../components/kpi.view";
import kpiStyle from "../styles/kpi.style";

export type ImgType =
  | "GRAPH"
  | "MONEY"
  | "CUSTOMER"
  | "CUSTOMER_LOST"
  | "NEW_CUSTOMER";
export type Props = {
  classes: { [key: string]: string },
  /**
   * Title for the KPI
   *
   * @type {?string}
   */
  titleNormal?: ?string,
  /**
   * Title with bold format
   *
   * @type {?string}
   */
  titleBold?: ?string,
  /**
   * Main value to display
   *
   * @type {string}
   */
  value: string,
  /**
   * Suffix for given value like k€
   *
   * @type {string}
   */
  suffix: string,
  /**
   * Growth to display
   *
   * @type {?string}
   */
  percentage?: ?string,
  /**
   * Caption to display on the b ottom KPI
   *
   * @type {?string}
   */
  bottomText?: ?string,
  /**
   * Indicates which image should be displayed
   *
   * @type {?ImgType}
   */
  imgType?: ?ImgType,
};
export type State = {};
class KPIContainer extends Component<Props, State> {
  static defaultProps = {
    titleNormal: null,
    titleBold: null,
    percentage: null,
    bottomText: null,
    imgType: "GRAPH",
  };

  componentDidMount = () => {
    console.log("DiDMount");
  };

  render() {
    return <KPIView {...this.props} />;
  }
}

const mapStateToProps = (/* state: GlobalState */) => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(kpiStyle)(KPIContainer));
