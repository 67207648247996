// @flow

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import badgeStyle from "../styles/badge.style";
// import type { State as GlobalState } from "core/reducer";
import BadgeView from "../components/badges.view";

export type Props = {
  classes: { [key: string]: string },
  /**
   * Indicate whether it's a badge or not which will display badge image
   *
   * @type {?boolean}
   */
  badge?: ?boolean,
  /**
   * Indicate whether it's a card or not which will display badge image
   *
   * @type {?boolean}
   */
  card?: ?boolean,
};
export type State = {};
class BadgeContainer extends Component<Props, State> {
  static defaultProps = {
    badge: false,
    card: false,
  };

  componentDidMount() {
    console.log("didMount");
  }

  render() {
    return <BadgeView {...this.props} value="35" growth="+2%" />;
  }
}

const mapStateToProps = (/* state: GlobalState */) => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(badgeStyle)(BadgeContainer));
