// @flow
import { createSelector } from "reselect";
import type { Actions } from "../../actions";
import type { User } from "../../models/user.entity";
import {
  USER_ME_SUCCESS,
  GET_USERS_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_FAILED,
  SET_FILTER_USERS,
  ADD_CUSTOM_FILTER,
  REMOVE_CUSTOM_FILTER,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
} from "../../actions/constants";

export type UserState = {|
  current: ?User,
  users: ?{ [key: string]: User },
  filter: string,
  customFilters: { [key: string]: any },
  removedFilters: Array<string>,
  filterEnabled: boolean,
  loading: boolean,
  totalUsers: number,
|};

export const initialState: UserState = {
  current: null,
  users: null,
  filter: "",
  customFilters: {},
  removedFilters: ["administrator", "active"],
  filterEnabled: false,
  loading: false,
  totalUsers: -1,
};

export const usersListSelector = (state: UserState): Array<User> => {
  if (state.users) {
    // $FlowFixMe
    return Object.keys(state.users).map(key => state.users[key]);
  }
  return [];
};

export const userSelector = (state: UserState, userId: ?string) => {
  if (state.users && userId != null) {
    return state.users[userId];
  }
  return null;
};
export const filterSelector = (state: UserState) => state.filter;
export const customFiltersSelector = (state: UserState) => state.customFilters;
export const removedFiltersSelector = (state: UserState) => state.removedFilters;
export const totalUsersSelector = (state: UserState) => state.totalUsers;
export const isSearchingSelector = createSelector(
  filterSelector,
  removedFiltersSelector,
  (filter, removedFilters) => {
    if (
      filter === ""
      && removedFilters.length === initialState.removedFilters.length
    ) {
      return false;
    }
    return true;
  },
);

export const userFilteredSelector = createSelector(
  customFiltersSelector,
  filterSelector,
  usersListSelector,
  (customFilters, filter, users) => {
    if (filter !== "" && filter != null) {
      return users.filter((user) => {
        const filtersValues = Object.keys(customFilters).map(
          key => user[key] === customFilters[key],
        );
        return (
          (user.firstName.match(filter)
            || user.lastName.match(filter)
            || user.email.match(filter)
            || user.igg.match(filter))
          && !filtersValues.includes(false)
        );
      });
    }
    if (Object.keys(customFilters).length > 0) {
      return users.filter((user) => {
        const filtersValues = Object.keys(customFilters).map(
          key => user[key] === customFilters[key],
        );
        return !filtersValues.includes(false);
      });
    }
    return users;
  },
);

const userReducer = (
  state: UserState = initialState,
  action: Actions,
): UserState => {
  switch (action.type) {
  case CREATE_USER_SUCCESS:
  case UPDATE_USER_SUCCESS: {
    return {
      ...state,
      ...{
        users: {
          ...state.users,
          ...{ [action.user.id]: action.user },
        },
      },
    };
  }
  case REMOVE_CUSTOM_FILTER: {
    const newFilters = { ...state.customFilters };
    delete newFilters[action.key];
    return {
      ...state,
      ...{
        customFilters: newFilters,
        removedFilters: [...state.removedFilters, action.key],
      },
    };
  }
  case ADD_CUSTOM_FILTER: {
    const { filter } = action;
    return {
      ...state,
      ...{
        customFilters: {
          ...state.customFilters,
          ...{ [action.filter.key]: filter.value },
        },
        removedFilters: state.removedFilters.filter(
          value => value !== filter.key,
        ),
      },
    };
  }
  case SET_FILTER_USERS: {
    return {
      ...state,
      ...{ filter: action.filter },
    };
  }
  case GET_USERS_FAILED: {
    return {
      ...state,
      ...{ loading: false },
    };
  }
  case GET_USERS_REQUEST: {
    return {
      ...state,
      ...{ loading: true },
    };
  }
  case GET_USERS_SUCCESS: {
    return {
      ...state,
      ...{
        users: { ...state.users, ...action.users },
        totalUsers: action.totalItems,
        loading: false,
      },
    };
  }
  case USER_ME_SUCCESS:
    return {
      ...state,
      ...{
        current: action.user,
      },
    };
  default:
    return state;
  }
};

export default userReducer;
