// @flow

import React, { Component } from "react";
import type { ComponentType } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import type { State as GlobalState } from "core/reducer";
import { initSession } from "core/users/actions/session.action";
import Routes from "../routes.def";

/**
 * High Order Component with provide an authentication check
 * If the user is loggedIn then he will be redirected to the login page
 *
 * @export
 * @param {ComponentType<*>} wrappedComponent
 * @returns
 */
export function withNoAuthentication(WrappedComponent: ComponentType<*>) {
  const mapDispatchToProps = {
    initSession,
  };

  const mapStateToProps = (state: GlobalState) => ({
    loggedIn: state.auth.loggedIn,
    autoLogFailed: state.auth.autoLogFailed,
  });

  type Props = {
    loggedIn: boolean,
    autoLogFailed: boolean,
    initSession: typeof initSession,
  };

  const UnAuthHoc = class extends Component<Props, {}> {
    componentDidMount() {
      const { initSession, autoLogFailed } = this.props;
      if (!autoLogFailed) {
        initSession(() => {});
      }
    }

    render() {
      const { loggedIn } = this.props;
      if (loggedIn === false) {
        return <WrappedComponent />;
      }
      return <Redirect to={Routes.DASHBOARD} />;
    }
  };
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UnAuthHoc);
}
