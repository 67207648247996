// @flow

export const styles = () => ({
  card: { width: 300 },
  container: { height: "100vh", backgroundColor: "lightgray" },
  errorText: { overflowX: "hidden", overflowY: "scroll", maxHeight: 400 },
  iconContainer: {
    borderRadius: 50,
    margin: 20,
    height: 100,
    width: 100,
    backgroundColor: "lightgray",
  },
  bugIcon: { fontSize: 50, color: "white" },
});
