// @flow
const Routes = {
  ROOT: "/",
  AUTH: "/auth",
  SIGN_IN: "/auth/sign-in",
  FIRST_CONNECT: "/auth/activate",
  PASSWORD_FORGOTTEN: "/auth/reset-password",
  RESET_PASSWORD: "/auth/password/:action",
  RESET_PASSWORD_WITH_TOKEN: "/auth/password/:action/:token", // action:new|update|reset
  ADMIN: "/dashboard/admin",
  DASHBOARD: "/dashboard",
  DASHBOARD_HOME: "/dashboard/home",
};

export default Routes;
