// @flow
import { api } from "./api";

export default class AuthApi {
  /**
   * Authenticate the user
   *
   * @static
   * @param {string} username
   * @param {string} password
   * @returns
   * @memberof AuthApi
   */
  static async login(username: string, password: string) {
    const response = await api
      .post("/login_check", { username, password })
      .catch(err => Promise.reject(err));
    return response;
  }

  /**
   * Request for first login, which will send an email to the user
   *
   * @static
   * @param {string} igg
   * @param {string} email
   * @returns response
   * @memberof AuthApi
   */
  static async firstLogin(igg: string, email: string) {
    const response = await api
      .post("/users/first-login/request", { igg, email })
      .catch(err => Promise.reject(err));
    return response;
  }

  /**
   * Request to register account.
   *
   * @static
   * @param {string} igg
   * @param {string} token received from firstLogin email
   * @param {string} password
   * @param {string} passwordConfirmation
   * @returns
   * @memberof AuthApi
   */
  static async accountRegistration(
    igg: string,
    token: string,
    password: string,
    passwordConfirmation: string,
  ) {
    const response = await api
      .post("/users/first-login/register", {
        igg,
        firstLoginToken: token,
        firstPassword: password,
        secondPassword: passwordConfirmation,
      })
      .catch(err => Promise.reject(err));
    return response;
  }

  /**
   * Web service used to update an existing password
   *
   * @static
   * @param {string} password
   * @param {string} passordConfirmation
   * @param {string} oldConfirmation
   * @returns
   * @memberof AuthApi
   */
  static async updatePassword(
    userId: number,
    oldPassword: string,
    password: string,
    passwordConfirmation: string,
  ) {
    const response = await api
      .put(`/users/${userId}/change-password`, {
        oldPassword,
        firstPassword: password,
        secondPassword: passwordConfirmation,
      })
      .catch(err => Promise.reject(err));
    return response;
  }

  /**
   * Reset a password for combination igg/email
   *
   * @static
   * @param {string} igg
   * @param {string} email
   * @returns {void}
   * @memberof AuthApi
   */
  static async askResetPassword(igg: string, email: string) {
    const response = await api
      .post("/users/reset-password/request", {
        email,
        igg,
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }

  /**
   * Reset the password
   *
   * @static
   * @param {string} token
   * @param {string} password
   * @param {string} passwordConfirmation
   * @returns
   * @memberof AuthApi
   */
  static async resetPassword(
    token: string,
    password: string,
    passwordConfirmation: string,
  ) {
    const response = await api
      .post("/users/reset-password/register", {
        resetPasswordToken: token,
        firstPassword: password,
        secondPassword: passwordConfirmation,
      })
      .catch(err => Promise.reject(err));
    return response;
  }

  /**
   * Check if password is still valid
   *
   * @static
   * @param {number} userId
   * @memberof AuthApi
   */
  static async checkPasswordValidity(userId: number) {
    await api
      .get(`/users/${userId}/password-expired.json`)
      .catch(err => Promise.reject(err));
  }
}
