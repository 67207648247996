// @flow
import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Strings from "web/assets/strings";
import BusinessFocusContainer from "../containers/business.focus.container";
import CardKPI from "./card.kpi";
import KPI from "../containers/kpi.container";
import KPIProgress from "../containers/kpi.progress.container";
import Badge from "../containers/badge.container";
import SupportCard from "../../shared/components/support.card.component";
import type { Props as ContainerProps } from "../containers/dashboard.container";

type Props = ContainerProps & {};
export default ({ classes }: Props) => (
  <Fragment>
    <BusinessFocusContainer />
    <Grid container spacing={8} direction="row" className={classes.container}>
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <CardKPI title="VOLUME" boldTitle="CARBURANT">
          <KPI value="56778" suffix=" M³" percentage="+2%" />
          <KPIProgress />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI title="CA" boldTitle="HORS CARBURANT">
          <KPI imgType="MONEY" value="6400" suffix=" k€" percentage="+2%" />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI title="" boldTitle="FDS">
          <KPI imgType="MONEY" value="6400" suffix=" k€" percentage="+2%" />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI title="MARGE" boldTitle="NETTE">
          <KPI
            imgType="MONEY"
            title="MARGE"
            boldTitle="NETTE"
            bottomText="12 €/m3"
            percentage="+2%"
            value="56778"
            suffix=" M³"
          />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI available={false} title="TAUX" boldTitle="DE PENETRATION" />
      </Grid>
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <CardKPI title="EVOLUTION" boldTitle="DES REMISES">
          <KPI
            value="6400"
            suffix=" k€"
            percentage="+2%"
            titleNormal="Remise"
            titleBold="Alternative"
          />
          <KPI
            value="6400"
            suffix=" k€"
            percentage="+2%"
            titleNormal="Remise"
            titleBold="Barème"
          />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI available={false} title="DSO" boldTitle="(EVOLUTION)" />
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI available={false} title="TAUX" boldTitle="DE COUVERTURE" />
      </Grid>
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <CardKPI title="VALEUR" boldTitle="CLIENT">
          <KPI value="1000" percentage="+2%" imgType="CUSTOMER" />
          <KPI value="35" bottomText="Clients perdus" imgType="CUSTOMER" />
          <KPI value="35" bottomText="Client gagnés" imgType="NEW_CUSTOMER" />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <SupportCard
          title={Strings.dashboard.customer}
          boldTitle={Strings.dashboard.andSupport}
        />
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI border title="" boldTitle="CARTES">
          <Badge card />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI border title="BADGES" boldTitle="LIBER T">
          <Badge badge />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI border title="BADGES" boldTitle="PASSANGO">
          <Badge badge />
        </CardKPI>
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI
          border
          available={false}
          title="BADGE"
          boldTitle="PASSANGO EUROPE"
        />
      </Grid>
      <Grid item md={2} sm={4} xs={12}>
        <CardKPI border available={false} title="NB" boldTitle="DE VEHICULES" />
      </Grid>
    </Grid>
  </Fragment>
);
