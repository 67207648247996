// @flow
import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import createSagaMiddleware, { END } from "redux-saga";
import type { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux";
import InfoMiddleware from "./middlewares/request.info.middleware";
import type { State as GlobalState } from "./reducer";
import type { Actions } from "./actions";
import rootReducer from "./reducer";

export type Store = ReduxStore<GlobalState, Actions>;
export type GetState = () => GlobalState;
export type Dispatch = ReduxDispatch<Actions>;

export type SagaStore = Store & {|
  runSaga: any,
  close: () => ReduxDispatch<typeof END>,
|};

export default function configureStore(
  initialState: ?GlobalState,
  callback: (message: string, type: "ERROR" | "SUCCESS") => void,
) {
  const sagaMiddleware = createSagaMiddleware();

  const store: SagaStore = createStore(
    // $FlowFixMe
    rootReducer,
    initialState,
    compose(applyMiddleware(sagaMiddleware, InfoMiddleware(callback), logger)),
  );
  store.runSaga = sagaMiddleware.run;
  // $FlowFixMe - END value is not contains by Actions
  store.close = () => store.dispatch(END);
  return { store };
}
