// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import Strings from "web/assets/strings";
import { Field } from "redux-form";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Fade from "@material-ui/core/Fade";
import PersonIcon from "@material-ui/icons/Person";
import LoadingButton from "../../shared/components/loading.button";
import RFTextField from "./textfield.component";
import type {
  Props as ContainerProps,
  State as ContainerState,
} from "../containers/update.password.container";
import PasswordIndicator from "./password.indicator.component";

type Props = ContainerProps &
  ContainerState & {
    onResetPasswordClick: () => Promise<void>,
    togglePasswordVisibility: () => void,
    touched: boolean,
    syncErrors: { [key: string]: boolean },
    title: string,
  };

const UpdatePasswordView = (props: Props) => {
  const {
    handleSubmit,
    classes,
    onResetPasswordClick,
    syncErrors,
    touched,
    togglePasswordVisibility,
    showPassword,
    title,
    match,
    loading,
  } = props;
  const { action } = match.params;
  return (
    <Fade in>
      <form onSubmit={handleSubmit(onResetPasswordClick)} className={`${classes.fullHeight}`}>
        <Grid container className={`${classes.fieldsContainer}`} justify="center">
          <Grid item className={`${classes.title}`}>
            {/* $FlowFixMe */}
            <Typography component="h2" variant="h5">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} className={`${classes.subtitle}`} />
          {action === "new" && (
            <Grid item xs={12}>
              <Field
                className={`${classes.authField}`}
                name="igg"
                icon={<PersonIcon className={classes.iconColor} />}
                component={RFTextField}
                placeholder={Strings.password.igg}
                variant="outlined"
                required
              />
            </Grid>
          )}
          {action === "update" && (
            <Grid item xs={12}>
              <Field
                className={`${classes.authField}`}
                name="oldPassword"
                type="password"
                icon={<LockIcon className={classes.iconColor} />}
                component={RFTextField}
                placeholder={Strings.password.oldPassword}
                variant="outlined"
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              className={`${classes.authField}`}
              name="password"
              component={RFTextField}
              icon={<LockIcon className={classes.iconColor} />}
              type={showPassword ? "text" : "password"}
              placeholder={Strings.password.newPassword}
              variant="outlined"
              required
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.endAdornment}>
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <PasswordIndicator
            success={syncErrors ? !syncErrors.needOneUpperCase : touched}
            title={Strings.password.containsUppercase}
          />
          <PasswordIndicator
            success={syncErrors ? !syncErrors.needOneLowerCase : touched}
            title={Strings.password.containsLowercase}
          />
          <PasswordIndicator
            success={syncErrors ? !syncErrors.needOneNumeric : touched}
            title={Strings.password.containsNumeric}
          />
          <PasswordIndicator
            success={syncErrors ? !syncErrors.needOneSpecialCharacter : touched}
            title={Strings.password.containsSpecialCaracters}
          />
          <PasswordIndicator
            success={syncErrors ? !syncErrors.containsEightCharacters : touched}
            title={Strings.password.eightCharsMin}
          />
          <Grid item xs={12}>
            <Field
              className={`${classes.authField}`}
              name="passwordConfirmation"
              type="password"
              icon={<LockIcon className={classes.iconColor} />}
              component={RFTextField}
              placeholder={Strings.password.newPasswordConfirmation}
              variant="outlined"
              required
            />
          </Grid>

          {syncErrors && syncErrors.passwordConfirmation && (
            <Grid xs={12} item>
              <Typography color="error">{syncErrors.passwordConfirmation}</Typography>
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              type="submit"
              className={`${classes.connectButton} ${classes.marginTop}`}
              text={Strings.global.reset}
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};

export default UpdatePasswordView;
