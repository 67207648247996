// @flow
import type { Theme } from "@material-ui/core";

export const authStyle = (theme: Theme) => ({
  buttonLink: { paddingTop: 12, height: 25 },
  connectButton: {
    backgroundColor: "#337ab7",
    "&:hover": {
      backgroundColor: "#184f9e",
    },
    color: "white",
    textTransform: "none",
    width: "20vw",
    minWidth: 200,
  },
  endAdornment: { position: "absolute", right: 0 },
  totalShadow: {
    boxShadow:
      "127px 1px 300px -135px rgba(255,0,0,1), 3px -30px 300px -97px rgba(247,153,28,1),3px 71px 300px -97px rgba(62,144,202,1)",
  },
  fieldsContainer: {
    padding: 50,
  },
  authField: {
    width: "100%",
    height: 40,
  },
  title: { textAlign: "center" },
  subtitle: { margin: 15 },
  logo: {
    height: 130,
    marginBottom: 40,
  },
  formContainer: {
    marginTop: "10vh",
  },
  fullHeight: {
    height: "100%",
  },
  iconColor: { color: "white" },
  marginTop: { marginTop: 50 },
  wordWrap: { whiteSpace: "pre-wrap;" },
  link: {
    textDecorationColor: theme.palette.primary.main,
  },
  fadeEnter: {
    opacity: 0,
    zIndex: 1,
  },
  fadeEnterActive: {
    opacity: 1,
    transition: "opacity 2000ms ease-in",
  },
});
