// @flow
import React from "react";
import ReactDOM from "react-dom";
import configureStore from "core/configureStore";
import rootSaga from "core/rootSaga";
import * as serviceWorker from "./web/serviceWorker";
import "./web/index.css";
// app specific imports
import Root from "./web/root";
import Toaster from "./web/utils/Toaster";

const config = configureStore(window.__INITIAL_STATE__, new Toaster().onMessageReceived);

config.store.runSaga(rootSaga);

// $FlowFixMe - React indicate an error with document.ElementById
ReactDOM.render(<Root config={config} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
