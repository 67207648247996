// @flow

export const BusinessFocusStyle = () => ({
  paper: {
    background:
      "linear-gradient(90deg, rgba(55,66,138,1) 0%, rgba(222,29,65,1) 50%)",
  },
  arrowForward: { color: "#fff", marginLeft: 5, fontWeight: "bold" },
  leftContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 30,
    paddingBottom: 10,
  },
  leftTitle: {
    color: "#fff",
    textAlign: "center",
  },
  divider: {
    margin: 10,
    backgroundColor: "#fff",
    width: "70%",
  },
  focusButton: {
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: "transparent",
    borderColor: "#fff",
    "&:hover": {
      color: "red",
    },
  },
  focusTextButton: {
    color: "#fff",
  },
  fullHeight: {
    height: "100%",
  },
  focusTextHoverButton: {
    color: "#553e7e",
  },
  rightContainer: {
    flex: 1,
    height: "95%",
    marginBottom: 10,
    paddingTop: 20,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  iconsLabel: {
    marginRight: 15,
    marginBottom: 5,
    color: "#6f6f6e",
  },
  labels: {
    color: "#6f6f6e",
  },
  labelsContainer: {
    marginLeft: 20,
    marginBottom: 10,
  },
  station: {
    width: "19px",
    height: "21px",
  },
  dataVisualisationImg: {
    width: "18px",
    height: "18px",
  },
});
