// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import Strings from "web/assets/strings";
import { Field } from "redux-form";
import EmailIcon from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import LoadingButton from "../../shared/components/loading.button";
import RFTextField from "./textfield.component";
import type {
  Props as ContainerProps,
  State as ContainerState,
} from "../containers/forgotten.password.container";

type Props = ContainerProps &
  ContainerState & {
    onResetPasswordClick: () => Promise<void>,
  };

const UpdatePasswordView = (props: Props) => {
  const {
    handleSubmit, classes, onResetPasswordClick, loading,
  } = props;
  return (
    <Fade in>
      <form onSubmit={handleSubmit(onResetPasswordClick)} className={`${classes.fullHeight}`}>
        <Grid container className={`${classes.fieldsContainer}`} justify="center">
          <Grid item className={`${classes.title}`}>
            {/* $FlowFixMe */}
            <Typography component="h2" variant="h5">
              {Strings.password.forgottenTitle}
            </Typography>
            <Typography variant="body2" className={`${classes.subtitle}`}>
              {Strings.password.forgottenExplanation}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              className={`${classes.authField}`}
              name="IGG"
              icon={<PersonIcon className={classes.iconColor} />}
              component={RFTextField}
              placeholder={Strings.auth.iggPlaceholder}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              className={`${classes.authField}`}
              name="mail"
              icon={<EmailIcon className={classes.iconColor} />}
              component={RFTextField}
              placeholder={Strings.global.email}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              className={`${classes.connectButton}`}
              text={Strings.global.reset}
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};

export default UpdatePasswordView;
