// @flow
import React from "react";
import type { Node } from "react";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { style } from "../styles/page.loader.style";

type Props = {
  classes: { [key: string]: string },
  loading: boolean,
  children: Node
};
const PageLoader = ({ classes, loading, children }: Props) => {
  if (loading) {
    return (
      <CircularProgress
        className={classes.loader}
        variant="indeterminate"
        size={60}
        thickness={4}
      />
    );
  }
  return children;
};

export default withStyles(style)(PageLoader);
