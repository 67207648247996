// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import type { Saga, Effect } from "redux-saga";
import type { AccountRegistrationRequestAction } from "../actions/account.registration.action.types";
import {
  accountRegistrationFailed,
  accountRegistrationSucceeded,
} from "../actions/account.registration.action";
import AuthApi from "../../api/auth.api";
import { ACCOUNT_REGISTRATION_REQUEST } from "../../actions/constants";

/**
 * Handle account registration process
 *
 * @param {AccountRegistrationRequestAction} action
 * @returns {Saga<void>}
 */
export function* accountRegistrationSaga(action: AccountRegistrationRequestAction): Saga<void> {
  try {
    yield call(
      AuthApi.accountRegistration,
      action.IGG,
      action.token,
      action.password,
      action.passwordConfirmation,
    );
    yield put(accountRegistrationSucceeded());
    // Trigger callback needed to return an error to reduxForm
    action.done();
  } catch (error) {
    yield put(accountRegistrationFailed(error));
    // Trigger callback needed to return an error to reduxForm
    action.done(error);
  }
}

/**
 * Watching for ACCOUNT_REGISTRATION_REQUEST EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export default function* watcherAccountRegistration(): Iterable<Effect> {
  yield takeLatest(ACCOUNT_REGISTRATION_REQUEST, accountRegistrationSaga);
}
