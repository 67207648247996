// @flow

import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BugReport from "@material-ui/icons/BugReport";
import Strings from "web/assets/strings";
import type { State as ContainerState } from "../containers/error.container";

type Props = ContainerState & {
  reload: () => void,
  classes: { [key: string]: string },
};

const ErrorBoundary = ({ error, classes, reload }: Props) => (
  <Grid
    container
    className={classes.container}
    justify="center"
    alignItems="center"
  >
    <Card className={classes.card}>
      <Grid container justify="center" alignItems="center">
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.iconContainer}
        >
          <BugReport className={classes.bugIcon} />
        </Grid>
      </Grid>
      <CardContent>
        <Typography color="textSecondary" variant="button" gutterBottom>
          {Strings.error.somethingWentWrong}
        </Typography>
        <div className={classes.errorText}>
          <Typography variant="body1" component="h2">
            {error}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={reload}>
          {Strings.error.reloadPage}
        </Button>
      </CardActions>
    </Card>
  </Grid>
);

export default ErrorBoundary;
