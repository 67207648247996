// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import DoneIcon from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";
import { iconColor } from "../styles/update.password.style";

const PasswordIndicator = ({ success, title }: {| success: boolean, title: string |}) => (
  <Grid item xs={12}>
    <Grid container style={{ direction: "row", spacing: 15 }}>
      <DoneIcon style={iconColor(success)} />
      <Typography variant="body2">{title}</Typography>
    </Grid>
  </Grid>
);
export default PasswordIndicator;
