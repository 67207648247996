// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import Strings from "web/assets/strings";
import { withStyles } from "@material-ui/core/styles";
import type { State as GlobalState } from "core/reducer";
import { activationRequest } from "core/authentication/actions/activate.action";
import { SubmissionError, reduxForm } from "redux-form";
import type { FormProps, Form } from "../../shared/custom-types-definitions/redux-form";
import { authStyle } from "../styles/auth.style";
import ActivateView from "../components/activate.view";

export type Props = {|
  +activationRequest: typeof activationRequest,
  +classes: { [key: string]: string },
  +activateForm: Form,
|} & FormProps;

export type State = {|
  loading: boolean,
|};

class ActivateContainer extends Component<$Exact<Props>, $Exact<State>> {
  onFirstConnectClick = () => new Promise((resolve, reject) => {
    const { activateForm, activationRequest } = this.props;
    this.setState(() => ({ loading: true }));
    if (activateForm.values) {
      activationRequest(activateForm.values.igg, activateForm.values.email, (error) => {
        if (error != null) {
          if (error.response && error.response.status) {
            switch (error.response.status) {
            case 409:
              reject(
                new SubmissionError({
                  _error: Strings.firstLogin.alreadyActivated,
                }),
              );
              break;
            case 401:
              reject(
                new SubmissionError({
                  _error: Strings.firstLogin.invalidUser,
                }),
              );
              break;
            default:
              reject(
                new SubmissionError({
                  _error: Strings.global.unexpectedError,
                }),
              );
            }
          } else {
            reject(new SubmissionError({ _error: error.message }));
          }
          this.setState(() => ({ loading: false }));
        } else {
          this.setState(() => ({ loading: false }));
          resolve();
        }
      });
    } else {
      this.setState(() => ({ loading: false }));
      reject(new SubmissionError({ _error: "ERROR" }));
    }
  });

  render() {
    return (
      <ActivateView
        onFirstConnectClick={this.onFirstConnectClick}
        {...this.state}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  activateForm: state.form.activate,
});

const mapDispatchToProps = {
  activationRequest,
};

// - Wrap with form HOC
const ActivateContainerForm = reduxForm({
  // a unique name for the form
  form: "activate",
})(ActivateContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(authStyle)(ActivateContainerForm));
