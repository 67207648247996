// @flow
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default withStyles({
  text: {
    textDecoration: "underline",
    textTransform: "none",
  },
  raised: {},
})(Button);
