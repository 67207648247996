// @flow
import type { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      height: window.innerHeight * 0.21,
    },
    [theme.breakpoints.down("md")]: {
      minHeight: window.innerHeight * 0.21,
      height: "auto",
    },
    transition: "0.4s",
    "&:hover": {
      marginTop: "-20px",
    },
    cursor: "pointer",
  },
  gridContainer: {
    height: "100%",
  },
  bottomGreyBorder: {
    borderBottom: "solid 6px rgba(135, 135, 134, 0.4)",
  },
  bottomOrangeBorder: {
    borderBottom: "solid 6px rgba(247, 151, 29, 0.8)",
  },
  soon: {
    paddingLeft: 5,
    paddingTop: 5,
  },
  childApiNotAvailable: {
    height: "70%",
  },
  childApi: {
    height: "70%",
  },
  fontSize: {
    fontSize: "1em",
  },
  fontColor: {
    color: "#6f6f6e",
  },
  titleContainer: {
    marginTop: 10,
  },
  centerText: {
    textAlign: "center",
  },
  title: {
    marginRight: 5,
    textAlign: "center",
  },
  divider: {
    height: 2,
    width: "100% !important",
    background:
      "linear-gradient(90deg, rgba(238,240,236,1) 0%, rgba(207,214,212,1) 50%, rgba(238,240,236,1) 100%)",
  },
});
