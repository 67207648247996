// @flow
import type { $AxiosError } from "axios";
import {
  INIT_SESSION,
  INIT_SESSION_FAILED,
  INIT_SESSION_SUCCESS,
  SESSION_LOGOUT,
  SESSION_LOGOUT_SUCCESS,
  SESSION_LOGOUT_FAILED,
} from "../../actions/constants";
import type {
  InitSessionAction,
  InitSessionFailedAction,
  InitSessionSuccessAction,
  SessionLogout,
  SessionLogoutFailed,
  SessionLogoutSucceeded,
} from "./session.action.types";

export const closeSession = (done: () => void): SessionLogout => ({
  type: SESSION_LOGOUT,
  done,
});

export const closeSessionSucceeded = (): SessionLogoutSucceeded => ({
  type: SESSION_LOGOUT_SUCCESS,
  toast: true,
});

export const closeSessionFailed = (
  error: $AxiosError<*, *>,
): SessionLogoutFailed => ({
  type: SESSION_LOGOUT_FAILED,
  error,
});

export const initSession = (
  done: (error: ?$AxiosError<*, *>) => void,
): InitSessionAction => ({
  type: INIT_SESSION,
  done,
});

export const initSessionFailed = (
  error: $AxiosError<*, *>,
): InitSessionFailedAction => ({
  type: INIT_SESSION_FAILED,
  error,
});

export const initSessionSucceeded = (): InitSessionSuccessAction => ({
  type: INIT_SESSION_SUCCESS,
});
