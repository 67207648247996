// @flow
import type { $AxiosError } from "axios";
import {
  ACCOUNT_REGISTRATION_FAILED,
  ACCOUNT_REGISTRATION_REQUEST,
  ACCOUNT_REGISTRATION_SUCCESS,
} from "../../actions/constants";

import type {
  AccountRegistrationSuccessAction,
  AccountRegistrationFailedAction,
  AccountRegistrationRequestAction,
} from "./account.registration.action.types";

export const requestForAccountRegistration = (
  IGG: string,
  token: string,
  password: string,
  passwordConfirmation: string,
  done: (error: ?$AxiosError<*, *>) => void,
): AccountRegistrationRequestAction => ({
  type: ACCOUNT_REGISTRATION_REQUEST,
  IGG,
  token,
  password,
  passwordConfirmation,
  done,
});

export const accountRegistrationFailed = (
  error: $AxiosError<*, *>,
): AccountRegistrationFailedAction => ({
  type: ACCOUNT_REGISTRATION_FAILED,
  toast: true,
  error,
});

export const accountRegistrationSucceeded = (): AccountRegistrationSuccessAction => ({
  type: ACCOUNT_REGISTRATION_SUCCESS,
  toast: true,
});
