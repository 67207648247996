// @flow
import type { Theme } from "@material-ui/core";

const drawerWidth = 260;
export const dashStyle = (theme: Theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  link: {
    textDecoration: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    background: "linear-gradient(45deg, #9d2135, #e4022d)",
    borderRight: "none",
    width: drawerWidth,
    // height: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    padding: 20,
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: drawerWidth,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  lightDivider: {
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  content: {
    padding: theme.spacing.unit * 2,
    flex: 1,
    overflow: "scroll",
    background:
      "linear-gradient(to bottom, #d3d2d2, #d8d7d7, #dcdcdc, #e1e1e1, #e6e6e6)",
  },
  noDecoration: {
    textDecoration: "none",
  },
  white: {
    color: "#ffffff",
  },
  listItemText: {
    color: "#ffffff",
    marginLeft: 10,
  },
  listItemFooter: {
    color: "#ffffff",
    marginLeft: 20,
  },
  hamburgerContainer: { marginTop: -10 },
  hamburgerText: { color: "white" },
  footerList: { bottom: 0, position: "absolute", width: drawerWidth },
  linkList: {
    marginTop: -20,
    marginBottom: 120,
    overflowX: "hidden",
    overflowY: "scroll",
  },
  logoCircle: { height: 40, marginLeft: -30, marginRight: 5 },
  logoText: { height: 30, marginLeft: 20 },
});
