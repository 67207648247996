// @flow
import memoize from "fast-memoize";

export default {
  global: {
    email: "Email",
    activate: "Activer",
    reset: "Réinitialiser",
    openMenu: "Ouvrir le menu",
    closeMenu: "Fermer le menu",
    unexpectedError: "Une erreur est survenue",
    emptyFields: "Champs vides, ou incorrects",
    igg: "IGG",
    username: "Prénom",
    firstname: "Prénom",
    lastname: "Nom",
    adminProfile: "Profil Administrateur",
    yes: "Oui",
    no: "Non",
    fieldsRequired: "Champs obligatoires",
    searchNoResult: "Aucun résultat pour ces critères",
    accountStatus: "Etat du compte",
    profile: "Profil",
    userProfile: "Profil Utilisateur",
    active: "Actif",
    inactive: "Inactif",
    disconnect: "Déconnexion",
    wouldYouDisconnect: "Êtes vous sur de vouloir fermer la session ?",
  },
  firstLogin: {
    alreadyActivated:
      "Vous vous êtes déjà connecté à la plate-forme. Si vous ne vous souvenez pas de votre mot de passe, veuillez cliquer sur le lien mot de passe oublié",
    invalidUser:
      "Vous n’êtes actuellement pas autorisé à utiliser l’outil, vous pouvez demander des droits d’accès en contactant Isabelle Eichler (isabelle.eichler@total.com)",
    emailSent: "Un email vient de vous être envoyé ",
  },
  auth: {
    iggPlaceholder: "Votre IGG",
    pwdPlaceholder: "Votre mot de passe",
    forgottenPassword: "Mot de passe oublié ?",
    firstConnection: "1ère connexion ?",
    wrongCredentials: "Les identifiants sont incorrects",
    connect: "Se connecter",
    passwordConfirmation: "Confirmation de mot de passe",
    title: "Connexion",
  },
  password: {
    igg: "IGG",
    passwordsNotMatch:
      "Les 2 mots de passe renseignés doivent être identiques. Veuillez ressaisir vos mots de passe",
    password: "Mot de passe",
    newPassword: "Nouveau mot de passe",
    newPasswordConfirmation: "Confirmation du nouveau mot de passe",
    forgottenTitle: "Mot de passe oublié",
    resetTitle: "Réinitialisation du mot de passe",
    updateTitle: "Mise à jour du mot de passe",
    newTitle: "Initialisation du mot de passe",
    forgottenExplanation:
      "Merci de renseigner votre addresse email et votre IGG. Vous recevrez alors un email avec votre nouveau mot de passe.",
    resetSubtitle: "Votre mot de passe doit contenir:",
    eightCharsMin: "8 caractères minimum",
    containsLowercase: "Une miniscule",
    containsUppercase: "Une majuscule",
    containsNumeric: "Un caractère numérique",
    containsSpecialCaracters: "Caractère Spécial (- _ ! @ # $ % ^)",
    oldPassword: "Ancien mot de passe",
  },
  activation: {
    title: "Première Connexion",
    subtitle: "Veuillez renseigner votre IGG dans le champ ci-dessous :",
  },
  dashboard: {
    title: "Tableau de bord",
    soon: "Bientôt",
    available: "Disponible",
    customers: "Clients",
    fuelConsumption: "CONSOMMATION CARBURANT",
    stationType: "PAR TYPE DE STATION",
    jobFocus: "Focus métier",
    fuelVolume: "Vol. carburant m³",
    repartition: "Répartition",
    customer: "Client",
    andSupport: "Et Support",
    filterMyNumbers: "Filtrer mes chiffres",
    searchCustomer: "Recherche client",
    period: "Période",
    level: "Niveau",
    resultTypes: "Type de Résultat",
    customerCategory: "Catégorie de client",
    selectFirstNode: "Select. un noeud 1",
    selectAggregatedCustomer: "Select. un client agrégé",
    taxOut: "Montant Hors taxes",
    taxOutCaption: "données à fin décembre",
    launch: "Lancer",
  },
  error: {
    somethingWentWrong: "Une erreur est survenue:",
    reloadPage: "Recharger la page",
    errorHappened: "L'erreur est la suivante :",
  },
  links: {
    actions: {
      title: "Mes actions",
      link: "https://www.bing.fr",
    },
    customer: {
      title: "Fiche Client",
      link: "https://www.bing.fr",
    },
    requests: {
      title: "Requêtes",
      link: "https://www.bing.fr",
    },
    rentabilitySimulator: {
      title: "Simulateur de rentabilité",
      link: "https://www.bing.fr",
    },
    grMap: {
      title: "Gr map",
      link: "https://www.bing.fr",
    },
    challenge: {
      title: "Challenge - PSV",
      link: "https://www.bing.fr",
    },
    commercialDoc: {
      title: "Doc. commerciale",
      link: "https://www.bing.fr",
    },
    concurrenceMonitoring: {
      title: "Veille concurentielle",
      link: "https://www.bing.fr",
    },
    crm: {
      title: "Crm",
      link: "https://www.bing.fr",
    },
    cardPro: {
      title: "Card Pro",
      link: "https://www.bing.fr",
    },
    salesForce: {
      title: "Sales force",
      link: "https://www.bing.fr",
    },
  },
  pagination: {
    rowsLabel: "Page %current_page% sur %total_page%",
  },
  admin: {
    createUser: "Créer un compte utilisateur",
    updateUser: "Modifier un compte utilisateur",
    createUserPlaceholder:
      "Code de rattachement, Nom, Prénom, IGG, ou addresse mail...",
    userActivated: "Utilisateur activé",
    attachmentCode: "Code de rattachement",
    userManagement: "Gestion des utilisateurs",
  },
  formErrors: {
    emptyField: "Veuillez saisir une valeur valide pour ce champ.",
    incorrectEmail: "L'email saisit est incorrect.",
  },
};

export const hydrate = memoize(
  (params: Array<{ key: string, value: string }>, value: string) => {
    let newValue = value;
    for (let i = 0; i < params.length; i += 1) {
      const p = params[i];
      newValue = newValue.replace(p.key, p.value);
    }
    return newValue;
  },
);
