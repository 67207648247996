// @flow
import type { Theme } from "@material-ui/core";

export const style = (theme: Theme) => ({
  loader: {
    position: "absolute",
    top: "calc(50vh - 30px)",
    left: "calc(50vw - 30px)",
    color: theme.palette.primary.main,
  },
});
