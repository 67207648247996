// @flow
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import DashboardContainer from "./containers/dashboard.container";
import Routes from "../shared/routes.def";
import AdminLoadable from "../admin/loadables/admin.loadable";

export default (
  <Switch>
    <Route exact path={Routes.DASHBOARD} component={DashboardContainer} />
    <Route path={Routes.ADMIN} component={AdminLoadable} />
    <Redirect to={Routes.DASHBOARD} />
  </Switch>
);
