// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import type { Saga, Effect } from "redux-saga";
import { normalize } from "normalizr";
import type {
  UserMeRequestAction,
  UsersRequestAction,
  CreateUserRequestAction,
  UpdateUserRequestAction,
} from "../actions/user.action.types";
import {
  userMeSucceeded,
  userMeFailed,
  getUsersSucceeded,
  getUsersFailed,
  createUserFailed,
  createUserSucceeded,
  updateUserFailed,
  updateUserSucceeded,
} from "../actions/user.action";
import UserApi from "../../api/user.api";
import type { User } from "../../models/user.entity";
import {
  USER_ME_REQUEST,
  GET_USERS_REQUEST,
  CREATE_USER_REQUEST,
  UPDATE_USER_REQUEST,
} from "../../actions/constants";
import { userSchema } from "../../schemas/user.schema";

/**
 * Get user me
 *
 * @param {UserMeRequestAction} action
 * @returns {Saga<void>}
 */
export function* userMeSaga(action: UserMeRequestAction): Saga<User | null> {
  try {
    const user = yield call(UserApi.getUserMe);
    yield put(userMeSucceeded(user));
    // Trigger callback needed to return an error to reduxForm
    return user;
  } catch (error) {
    yield put(userMeFailed(error));
    // Trigger callback needed to return an error to reduxForm
    action.done(error);
    return null;
  }
}

/**
 * Get user me
 *
 * @param {UserMeRequestAction} action
 * @returns {Saga<void>}
 */
export function* getUsersSaga(
  action: UsersRequestAction,
): Saga<Array<User> | null> {
  try {
    const { users, totalItems } = yield call(
      UserApi.getUsers,
      action.search,
      action.active,
      action.administrator,
      action.itemsPerPage,
      action.page,
    );
    const normalizedUsers: {
      entities: { users: { [key: string]: User } },
    } = normalize(
      { users },
      {
        users: [userSchema],
      },
    );
    yield put(getUsersSucceeded(normalizedUsers.entities.users, totalItems));
    // Trigger callback needed to return an error to reduxForm
    action.done();
    return users;
  } catch (error) {
    yield put(getUsersFailed(error));
    // Trigger callback needed to return an error to reduxForm
    action.done(error);
    return null;
  }
}

export function* createUserSaga(action: CreateUserRequestAction): Saga<void> {
  try {
    const user = yield call(UserApi.createUser, action.user);
    yield put(createUserSucceeded(user));
    action.done();
  } catch (err) {
    yield put(createUserFailed(err));
    action.done(err);
  }
}

export function* updateUserSaga(action: UpdateUserRequestAction): Saga<void> {
  try {
    const user = yield call(UserApi.updateUser, action.user);
    yield put(updateUserSucceeded(user));
    action.done();
  } catch (err) {
    yield put(updateUserFailed(err));
    action.done(err);
  }
}
/**
 * Watching for GET_USERS_REQUEST
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export function* getUsersWatcherSaga(): Iterable<Effect> {
  yield takeLatest(GET_USERS_REQUEST, getUsersSaga);
}

/**
 * Watching for USER_ME_REQUEST EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export function* userWatcherSaga(): Iterable<Effect> {
  yield takeLatest(USER_ME_REQUEST, userMeSaga);
}

export function* createUserWatcherSaga(): Iterable<Effect> {
  yield takeLatest(CREATE_USER_REQUEST, createUserSaga);
}

export function* updateUserWatcherSaga(): Iterable<Effect> {
  yield takeLatest(UPDATE_USER_REQUEST, updateUserSaga);
}
