// @flow
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import { withStyles } from "@material-ui/core/styles";

type Props = {|
  className: string,
  type: ?string,
  loading: boolean,
  text: string,
  classes: { [key: string]: string },
|};

const style = () => ({
  progress: {
    color: "#ffffff",
  },
});

const LoadingButton = ({
  className, type, loading, text, classes,
}: Props) => (
  <Button type={type} className={className}>
    {!loading && (
      <Grow in={!loading}>
        <div>{text}</div>
      </Grow>
    )}
    {loading && (
      <Grow in={loading}>
        <CircularProgress size={30} className={`${classes.progress}`} />
      </Grow>
    )}
  </Button>
);

export default withStyles(style)(LoadingButton);
