// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import type { Node } from "react";
import UpdateIcon from "@material-ui/icons/Update";
import Divider from "@material-ui/core/Divider";
import Strings from "web/assets/strings";
import CustomTypography from "../../shared/components/custom.typography.component";
import cardStyle from "../styles/card.kpi.style";

type Props = {
  classes: { [key: string]: string, title: string, boldTitle: string },
  /**
   * Accept many children such as KPI
   *
   * @type {?Node}
   */
  children?: ?Node,
  /**
   * Indicate whether the KPI is available or not
   *
   * @type {boolean}
   */
  available?: boolean,
  /**
   * Title for KPI CARD
   *
   * @type {string}
   */
  title: string,
  /**
   * Show border on the bottom card
   * If card is available and border also, it will show an orange border
   * If card is not available but border is, it will show grey border
   * @type {?boolean}
   */
  border?: ?boolean,
  /**
   * Bold title for KPI Card
   *
   * @type {string}
   */
  boldTitle: string,
};
const CardKPI = ({
  classes,
  children,
  available,
  title,
  boldTitle,
  border,
}: Props) => (
  <Paper
    elevation={4}
    className={`${
      border === true && available === true ? classes.bottomOrangeBorder : ""
    } ${
      border === true && available === false ? classes.bottomGreyBorder : ""
    } ${classes.container}`}
    style={{
      backgroundColor: available !== true ? "#E9E9E9" : "#fff",
    }}
  >
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      {/* Header  */}
      <Grid item xs={12} className={classes.titleContainer}>
        <Grid container direction="row" justify="center" alignItems="center">
          <CustomTypography
            thin
            className={`${classes.title} ${classes.fontColor} ${
              classes.fontSize
            }`}
          >
            {title}
          </CustomTypography>
          <CustomTypography
            className={`${classes.fontSize} ${classes.centerText}`}
            bold
          >
            {boldTitle}
          </CustomTypography>
        </Grid>
      </Grid>
      {/* Divider */}
      <Grid item container justify="center" alignItems="center" spacing={0}>
        <Grid item xs={8}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      {/* Content if props available indicates true */}
      {available === true && (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          // $FlowFixMe - FlowTyped not updated
          justify="space-evenly"
          direction="row"
          className={classes.childApi}
        >
          {children}
        </Grid>
      )}
      {/* Content if props available indicates false */}
      {available === false && (
        <Grid
          item
          container
          xs={12}
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.childApiNotAvailable}
        >
          <Grid item container justify="center" alignItems="center">
            <UpdateIcon
              className={classes.fontColor}
              style={{ fontSize: 20 }}
            />
            <CustomTypography className={classes.soon} size={20}>
              {Strings.dashboard.soon}
            </CustomTypography>
          </Grid>
          <CustomTypography size={20} bold>
            {Strings.dashboard.available}
          </CustomTypography>
        </Grid>
      )}
    </Grid>
  </Paper>
);

CardKPI.defaultProps = {
  available: true,
  border: false,
  children: null,
};

export default withStyles(cardStyle)(CardKPI);
