// @flow
import type { Actions } from "../../actions";
import {
  INIT_SESSION_SUCCESS,
  ACCOUNT_REGISTRATION_SUCCESS,
  INIT_SESSION_FAILED,
  PASSWORD_VALIDITY_FAILED,
  PASSWORD_UPDATE_SUCCESS,
} from "../../actions/constants";

export type State = {
  loggedIn: boolean,
  autoLogFailed: boolean,
  upgradeRequired: boolean,
};

export const initialState: State = {
  loggedIn: false,
  autoLogFailed: false,
  upgradeRequired: false,
};

const authReducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
  case PASSWORD_UPDATE_SUCCESS:
    return { ...state, ...{ upgradeRequired: false } };
  case PASSWORD_VALIDITY_FAILED:
    return { ...state, ...{ upgradeRequired: true } };
  case INIT_SESSION_FAILED:
    return { ...state, ...{ autoLogFailed: true } };
  case INIT_SESSION_SUCCESS:
    return {
      ...state,
      ...{ loggedIn: true, autoLogFailed: false },
    };
  case ACCOUNT_REGISTRATION_SUCCESS:
    return {
      ...state,
      ...{ loggedIn: true, autoLogFailed: false },
    };
  default:
    return state;
  }
};
export default authReducer;
