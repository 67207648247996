// @flow

import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Strings from "web/assets/strings";
import CustomTypography from "../../shared/components/custom.typography.component";
import RoundedIconButton from "../../shared/components/rounded.button.component";
import DataVisualisation from "../../assets/images/data_visualisation/datavisualisation.png";
import DataVisualisationx2 from "../../assets/images/data_visualisation/datavisualisation@2x.png";
import DataVisualisationx3 from "../../assets/images/data_visualisation/datavisualisation@3x.png";
import Station from "../../assets/images/station/station.png";
import Stationx2 from "../../assets/images/station/station@2x.png";
import Stationx3 from "../../assets/images/station/station@3x.png";
import TotalHighway from "../../assets/images/total_highway/highway.png";
import TotalHighwayx2 from "../../assets/images/total_highway/highway@2x.png";
import TotalHighwayx3 from "../../assets/images/total_highway/highway@3x.png";

import TotalHighwayOut from "../../assets/images/total_highway_out/highway_out.png";
import TotalHighwayOutx2 from "../../assets/images/total_highway_out/highway_out@2x.png";
import TotalHighwayOutx3 from "../../assets/images/total_highway_out/highway_out@3x.png";

import TotalAccess from "../../assets/images/total_access/access.png";
import TotalAccessx2 from "../../assets/images/total_access/access@2x.png";
import TotalAccessx3 from "../../assets/images/total_access/access@3x.png";

import TotalElan from "../../assets/images/total_elan/elan.png";
import TotalElanx2 from "../../assets/images/total_elan/elan@2x.png";
import TotalElanx3 from "../../assets/images/total_elan/elan@3x.png";

import FuelStationType from "../containers/fuel.station.container";
import type {
  Props as ContainerProps,
  State as ContainerState,
} from "../containers/business.focus.container";

type Props = ContainerProps & ContainerState & {};
const BusinessFocusView = ({ classes }: Props) => (
  <Paper className={classes.paper}>
    <Grid container className={classes.container} direction="row">
      <Grid item xs={12} md={3}>
        <Grid
          container
          className={classes.leftContainer}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <CustomTypography
              thin
              variant="subheading"
              className={classes.leftTitle}
            >
              {Strings.dashboard.fuelConsumption}
            </CustomTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CustomTypography
                  variant="button"
                  className={`${classes.leftTitle}`}
                >
                  {Strings.dashboard.stationType}
                </CustomTypography>
              </Grid>
              <Grid item>
                <ArrowForward className={classes.arrowForward} />
              </Grid>
            </Grid>
          </Grid>
          <Divider light className={classes.divider} />
          <Grid item xs={12}>
            <RoundedIconButton
              className={classes.focusButton}
              textClassName={classes.focusTextButton}
              textHoverClassName={classes.focusTextHoverButton}
              Icon={ArrowForward}
              empty
              title={Strings.dashboard.jobFocus}
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={9} xs={12}>
        <Paper className={classes.rightContainer}>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justify="space-between"
          >
            <Grid
              item
              container
              justify="flex-end"
              direction="column"
              alignItems="center"
              md={3}
              xs={12}
            >
              <Grid
                item
                container
                direction="row"
                className={classes.labelsContainer}
                alignItems="center"
              >
                <img
                  alt="data-visualisation"
                  src={Station}
                  srcSet={`${Stationx2}, ${Stationx3}`}
                  className={`${classes.dataVisualisationImg} ${
                    classes.iconsLabel
                  }`}
                />
                <CustomTypography bold size={15} className={classes.labels}>
                  {Strings.dashboard.fuelVolume}
                </CustomTypography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                className={classes.labelsContainer}
                alignItems="center"
              >
                <img
                  alt="data-visualisation"
                  src={DataVisualisation}
                  srcSet={`${DataVisualisationx2}, ${DataVisualisationx3}`}
                  className={`${classes.dataVisualisationImg} ${
                    classes.iconsLabel
                  }`}
                />
                <CustomTypography size={15} bold className={classes.labels}>
                  {Strings.dashboard.repartition}
                </CustomTypography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              direction="column"
              alignItems="center"
              md={2}
              xs={12}
            >
              <FuelStationType
                imgSrc={TotalHighwayOut}
                imgSrcSet={`${TotalHighwayOutx2} ${TotalHighwayOutx3}`}
              />
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              direction="column"
              alignItems="center"
              md={2}
              xs={12}
            >
              <FuelStationType
                imgSrc={TotalHighway}
                imgSrcSet={`${TotalHighwayx2} ${TotalHighwayx3}`}
              />
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              direction="column"
              alignItems="center"
              md={2}
              xs={12}
            >
              <FuelStationType
                imgSrc={TotalAccess}
                imgSrcSet={`${TotalAccessx2} ${TotalAccessx3}`}
              />
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              direction="column"
              alignItems="center"
              md={2}
              xs={12}
            >
              <FuelStationType
                imgSrc={TotalElan}
                imgSrcSet={`${TotalElanx2}, ${TotalElanx3}`}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </Paper>
);

export default BusinessFocusView;
