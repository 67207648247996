// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import type { State as GlobalState } from "core/reducer";
import type { RouterHistory, Match } from "react-router-dom";
import Strings from "web/assets/strings";
import { requestForAccountRegistration } from "core/authentication/actions/account.registration.action";
import {
  updatePasswordRequest,
  resetPasswordRequest,
} from "core/authentication/actions/password.action";
import type { User } from "core/models/user.entity";
import { authStyle } from "../styles/auth.style";
import type {
  FormProps,
  Form,
} from "../../shared/custom-types-definitions/redux-form";
import PasswordView from "../components/update.password.view";
import Routes from "../../shared/routes.def";

export type Props = {
  +classes: { [key: string]: string },
  +passwordForm: Form,
  +match: Match,
  +user: ?User,
  +history: RouterHistory,
  +resetPasswordRequest: typeof resetPasswordRequest,
  +requestForAccountRegistration: typeof requestForAccountRegistration,
  +updatePasswordRequest: typeof updatePasswordRequest,
} & FormProps;

export type State = {|
  loading: boolean,
  showPassword: boolean,
|};

class UpdatePasswordContainer extends Component<Props, State> {
  state = {
    showPassword: false,
    loading: false,
  };

  componentDidMount() {
    const { match, history, user } = this.props;
    const { action, token } = match.params;
    // - Check route
    if (action != null) {
      if (
        action !== "update"
        && (action !== "reset" || token == null)
        && (action !== "new" || token == null)
      ) {
        // - Action is not handled : Redirect the user
        history.replace(Routes.SIGN_IN);
      } else if (action === "update" && !user) {
        // - User must be logged to update his password
        history.replace(Routes.SIGN_IN);
      }
    } else {
      // - An action must be defined
      history.replace(Routes.SIGN_IN);
    }
  }

  togglePasswordVisibility = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  registerAccount = (
    resolve: (result?: ?Promise<void>) => void,
    reject: (error: any) => void,
    token: string,
  ) => {
    this.setState(() => ({ loading: true }));
    const { passwordForm, requestForAccountRegistration, history } = this.props;
    requestForAccountRegistration(
      passwordForm.values.igg,
      token,
      passwordForm.values.password,
      passwordForm.values.passwordConfirmation,
      (error) => {
        if (error) {
          reject(new SubmissionError({ _error: error.message }));
        } else {
          history.push(Routes.DASHBOARD);
          resolve();
        }
        this.setState(() => ({ loading: false }));
      },
    );
  };

  updatePassword = (
    resolve: (result?: ?Promise<void>) => void,
    reject: (error: any) => void,
  ) => {
    const {
      passwordForm, updatePasswordRequest, user, history,
    } = this.props;
    if (user) {
      this.setState(() => ({ loading: true }));
      updatePasswordRequest(
        user.id,
        passwordForm.values.oldPassword,
        passwordForm.values.password,
        passwordForm.values.passwordConfirmation,
        (error) => {
          if (error) {
            reject();
          } else {
            // - User has successfully update his password
            history.push(Routes.DASHBOARD);
            resolve();
          }
          this.setState(() => ({ loading: false }));
        },
      );
    }
  };

  resetPassword = (
    resolve: (result?: ?Promise<void>) => void,
    reject: (error: any) => void,
    token: string,
  ) => {
    const { passwordForm, resetPasswordRequest, history } = this.props;
    this.setState(() => ({ loading: true }));
    resetPasswordRequest(
      token,
      passwordForm.values.password,
      passwordForm.values.passwordConfirmation,
      (error) => {
        if (error) {
          reject(new SubmissionError({ _error: error.message }));
        } else {
          history.push(Routes.DASHBOARD);
          resolve();
        }
        this.setState(() => ({ loading: false }));
      },
    );
  };

  handlers = {
    new: this.registerAccount,
    update: this.updatePassword,
    reset: this.resetPassword,
  };

  onSetPasswordClick = () => new Promise((resolve, reject) => {
    const { match, passwordForm } = this.props;
    const { action, token } = match.params;
    if (passwordForm.values != null && action != null) {
      this.handlers[action](resolve, reject, token);
    } else {
      reject(new SubmissionError({ _error: Strings.global.emptyFields }));
    }
  });

  titles = {
    reset: Strings.password.resetTitle,
    new: Strings.password.newTitle,
    update: Strings.password.updateTitle,
  };

  render() {
    const { passwordForm, match, ...rest } = this.props;
    let touched = false;
    if (passwordForm && passwordForm.values) {
      if (
        passwordForm.values.password
        && passwordForm.values.password.length !== 0
      ) {
        touched = true;
      }
    }

    return (
      <PasswordView
        onResetPasswordClick={this.onSetPasswordClick}
        togglePasswordVisibility={this.togglePasswordVisibility}
        touched={touched}
        title={
          match && match.params && match.params.action != null
            ? this.titles[match.params.action]
            : ""
        }
        syncErrors={passwordForm ? passwordForm.syncErrors : null}
        match={match}
        {...this.state}
        {...rest}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  passwordForm: state.form.passwordForm,
  user: state.user.current,
});

const validateForm = (values: { [key: string]: string }) => {
  const errors = {};
  if (values.password !== "" && values.password != null) {
    if (!/^(?=.*[a-z])/.test(values.password)) {
      errors.needOneLowerCase = true;
    }
    if (!/^(?=.*[A-Z])/.test(values.password)) {
      errors.needOneUpperCase = true;
    }
    if (!/^(?=.*[0-9])/.test(values.password)) {
      errors.needOneNumeric = true;
    }
    if (!/^(?=.*[-_!@#\\$%\\^])/.test(values.password)) {
      errors.needOneSpecialCharacter = true;
    }
    if (!/^(?=.{8,})/.test(values.password)) {
      errors.containsEightCharacters = true;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^\\*])(?=.{8,})/.test(
        values.password,
      )
    ) {
      errors.password = "Not strong enough";
    }
  }
  if (values.password != null && values.passwordConfirmation != null) {
    if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = Strings.password.passwordsNotMatch;
    }
  }
  return errors;
};

const mapDispatchToProps = {
  requestForAccountRegistration,
  updatePasswordRequest,
  resetPasswordRequest,
};

const UpdatePasswordContainerForm = reduxForm({
  form: "passwordForm",
  validate: validateForm,
})(UpdatePasswordContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(authStyle)(UpdatePasswordContainerForm));
