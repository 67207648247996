// @flow

// - Authentication
export const AUTH_SUCCESS: "AUTH_SUCCESS" = "AUTH_SUCCESS";
export const AUTH_FAILED: "AUTH_FAILED" = "AUTH_FAILED";
export const AUTH_REQUEST: "AUTH_REQUEST" = "AUTH_REQUEST";
export const SESSION_LOGOUT: "SESSION_LOGOUT" = "SESSION_LOGOUT";
export const SESSION_LOGOUT_SUCCESS: "SESSION_LOGOUT_SUCCESS" = "SESSION_LOGOUT_SUCCESS";
export const SESSION_LOGOUT_FAILED: "SESSION_LOGOUT_FAILED" = "SESSION_LOGOUT_FAILED";
// - Account activation
export const ACTIVATION_REQUEST: "ACTIVATION_REQUEST" = "ACTIVATION_REQUEST";
export const ACTIVATION_SUCCESS: "ACTIVATION_SUCCESS" = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAILED: "ACTIVATION_FAILED" = "ACTIVATION_FAILED";
// - Account registration
export const ACCOUNT_REGISTRATION_REQUEST: "ACCOUNT_REGISTRATION_REQUEST" = "ACCOUNT_REGISTRATION_REQUEST";
export const ACCOUNT_REGISTRATION_SUCCESS: "ACCOUNT_REGISTRATION_SUCCESS" = "ACCOUNT_REGISTRATION_SUCCESS";
export const ACCOUNT_REGISTRATION_FAILED: "ACCOUNT_REGISTRATION_FAILED" = "ACCOUNT_REGISTRATION_FAILED";
// - Password Reset
export const PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST" = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS" = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILED: "PASSWORD_RESET_FAILED" = "PASSWORD_RESET_FAILED";
// - Password update
export const PASSWORD_UPDATE_REQUEST: "PASSWORD_UPDATE_REQUEST" = "PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_SUCCESS: "PASSWORD_UPDATE_SUCCESS" = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAILED: "PASSWORD_UPDATE_FAILED" = "PASSWORD_UPDATE_FAILED";
// - Password Reset
export const PASSWORD_ASK_RESET_REQUEST: "PASSWORD_ASK_RESET_REQUEST" = "PASSWORD_ASK_RESET_REQUEST";
export const PASSWORD_ASK_RESET_SUCCESS: "PASSWORD_ASK_RESET_SUCCESS" = "PASSWORD_ASK_RESET_SUCCESS";
export const PASSWORD_ASK_RESET_FAILED: "PASSWORD_ASK_RESET_FAILED" = "PASSWORD_ASK_RESET_FAILED";
// - Get user account
export const USER_ME_REQUEST: "USER_ME_REQUEST" = "USER_ME_REQUEST";
export const USER_ME_SUCCESS: "USER_ME_SUCCESS" = "USER_ME_SUCCESS";
export const USER_ME_FAILED: "USER_ME_FAILED" = "USER_ME_FAILED";
// - Get users
export const GET_USERS_REQUEST: "GET_USERS_REQUEST" = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS: "GET_USERS_SUCCESS" = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED: "GET_USERS_FAILED" = "GET_USERS_FAILED";
// - Create User
export const CREATE_USER_REQUEST: "CREATE_USER_REQUEST" = "CREATE_USER_REQUEST";
export const CREATE_USER_FAILED: "CREATE_USER_FAILED" = "CREATE_USER_FAILED";
export const CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS" = "CREATE_USER_SUCCESS";
// - Create User
export const UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST" = "UPDATE_USER_REQUEST";
export const UPDATE_USER_FAILED: "UPDATE_USER_FAILED" = "UPDATE_USER_FAILED";
export const UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS" = "UPDATE_USER_SUCCESS";
// - Init session
export const INIT_SESSION: "INIT_SESSION" = "INIT_SESSION";
export const INIT_SESSION_FAILED: "INIT_SESSION_FAILED" = "INIT_SESSION_FAILED";
export const INIT_SESSION_SUCCESS: "INIT_SESSION_SUCCESS" = "INIT_SESSION_SUCCESS";
// - Check password validity
export const CHECK_PASSWORD_VALIDITY: "CHECK_PASSWORD_VALIDITY" = "CHECK_PASSWORD_VALIDITY";
export const PASSWORD_VALIDITY_FAILED: "PASSWORD_VALIDITY_FAILED" = "PASSWORD_VALIDITY_FAILED";
export const PASSWORD_VALIDITY_SUCCESS: "PASSWORD_VALIDITY_SUCCESS" = "PASSWORD_VALIDITY_SUCCESS";
// - Filter
export const SET_FILTER_USERS: "SET_FILTER_USERS" = "SET_FILTER_USERS";
export const ADD_CUSTOM_FILTER: "ADD_CUSTOM_FILTER" = "ADD_CUSTOM_FILTER";
export const REMOVE_CUSTOM_FILTER: "REMOVE_CUSTOM_FILTER" = "REMOVE_CUSTOM_FILTER";
export const SET_FILTER_USERS_ENABLED: "SET_FILTER_USERS_ENABLED" = "SET_FILTER_USERS_ENABLED";
