// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import Badge from "../../assets/images/badge/badge.png";
import Badge2x from "../../assets/images/badge/badge@2x.png";
import Badge3x from "../../assets/images/badge/badge@3x.png";
import Card from "../../assets/images/card/card.png";
import Card2x from "../../assets/images/card/card@2x.png";
import Card3x from "../../assets/images/card/card@3x.png";
import CustomTypography from "../../shared/components/custom.typography.component";
import PositifIcon from "../../assets/images/positif/positif-negatif-copie-2.png";
import PositifIcon2x from "../../assets/images/positif/positif-negatif-copie-2@2x.png";
import PositifIcon3x from "../../assets/images/positif/positif-negatif-copie-2@3x.png";
import type {
  State as ContainerState,
  Props as ContainerProps,
} from "../containers/badge.container";

type Props = ContainerState &
  ContainerProps & { value: string, growth: string };
const BadgeView = ({
  classes, badge, card, value, growth,
}: Props) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    className={classes.test}
  >
    <Grid item>
      {badge === true && (
        <img
          alt="card"
          src={Badge}
          srcSet={`${Badge2x}, ${Badge3x}`}
          className={`${classes.icon}`}
        />
      )}
      {card === true && (
        <img
          alt="badge"
          src={Card}
          srcSet={`${Card2x}, ${Card3x}`}
          className={`${classes.icon}`}
        />
      )}
    </Grid>
    <Grid item className={classes.rightContainer}>
      <Grid container direction="column" justify="center">
        <CustomTypography size={22} className={classes.value}>
          {value}
        </CustomTypography>
        <CustomTypography size={21} className={classes.growth}>
          {growth}
          <img
            alt="graph"
            src={PositifIcon}
            srcSet={`${PositifIcon2x}, ${PositifIcon3x}`}
            className={classes.growthIcon}
          />
        </CustomTypography>
      </Grid>
    </Grid>
  </Grid>
);
export default BadgeView;
