// @flow
import React from "react";
import Typography from "@material-ui/core/Typography";
import type { Node } from "react";
import { withStyles } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";

type Props = {
  bold?: ?boolean,
  thin?: ?boolean,
  children: Node,
  className: string,
  classes: { [key: string]: string },
  width: string,
  size?: ?number,
};

function calculateFontSize(size: ?number, width: string) {
  switch (width) {
  case "xl":
    return size + 6;
  default:
    return size;
  }
}
const CustomTypography = ({
  bold,
  thin,
  children,
  className,
  classes,
  width,
  size,
  ...rest
}: Props) => (
  <Typography
    style={{ fontSize: calculateFontSize(size, width) }}
    className={`${className} ${bold === true ? classes.bold : ""} ${
      thin === true ? classes.thin : ""
    } ${thin !== true && bold !== true ? classes.normal : ""}`}
    {...rest}
  >
    {children}
  </Typography>
);

CustomTypography.defaultProps = {
  bold: false,
  thin: false,
  size: 19,
};

export default withWidth()(
  withStyles({
    bold: {
      fontFamily: "HelveticaNeueBold",
    },
    thin: {
      fontFamily: "HelveticaNeueThin",
    },
    normal: {
      fontFamily: "HelveticaNeueNormal",
    },
  })(CustomTypography),
);
