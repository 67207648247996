// @flow
import type { $AxiosError } from "axios";
import { AUTH_SUCCESS, AUTH_FAILED, AUTH_REQUEST } from "../../actions/constants";

import type {
  AuthenticationFailedAction,
  AuthenticationRequestAction,
  AuthenticationSuccessAction,
} from "./auth.action.types";

export const authSucceeded = (): AuthenticationSuccessAction => ({
  type: AUTH_SUCCESS,
  toast: true,
});

export const authRequest = (
  username: string,
  password: string,
  done: (error: ?$AxiosError<*, *>) => void,
): AuthenticationRequestAction => ({
  type: AUTH_REQUEST,
  username,
  password,
  done,
});

export const authFailed = (error: $AxiosError<*, *>): AuthenticationFailedAction => ({
  type: AUTH_FAILED,
  toast: true,
  error,
});
