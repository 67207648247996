// @flow

import React from "react";
import { withStyles } from "@material-ui/core";

export type Props = {
  classes: { [key: string]: string },
  open: boolean,
};

const HamburgerStyle = () => ({
  openBurgerMenu: {
    height: 45,
    width: 45,
    marginLeft: -10,
    display: "inline-block",
    cursor: "pointer",
    right: "20px",
    bottom: "40px",
    zIndex: "9999",
    padding: "10px",
    transition: "0.5s",
  },
  openMenu: {
    transform: "rotate(360deg)",
  },
  closeMenu: {
    transform: "rotate(180)deg",
  },
  bar: {
    width: "25px",
    height: "3px",
    backgroundColor: "#fff",
    margin: "4px 0",
    transition: "0.4s",
  },
  openBar1: {
    transform: "rotate(-45deg) translate(-4px, -4px)",
    width: "20px",
  },
  openBar2: {
    opacity: 1,
    margin: -1,
  },
  openBar3: {
    transform: "rotate(45deg) translate(-4px, 4px)",
    width: "20px",
  },
});

const HamburgerMenu = ({ classes, open }: Props) => (
  <div
    className={`${classes.openBurgerMenu} ${
      open ? classes.openMenu : classes.closeMenu
    }`}
  >
    <div
      className={`${classes.bar} ${open ? classes.openBar1 : ""}`}
      key="b1"
    />
    <div
      className={`${classes.bar} ${open ? classes.openBar2 : ""}`}
      key="b2"
    />
    <div
      className={`${classes.bar} ${open ? classes.openBar3 : ""}`}
      key="b3"
    />
  </div>
);

export default withStyles(HamburgerStyle)(HamburgerMenu);
