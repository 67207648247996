// @flow
import React from "react";
import Loadable from "react-loadable";

const Loading = () => <div />;
const LoadablePackage = Loadable({
  loader: () => import("./../containers/admin.container.js"),
  loading: Loading,
});

const AdminLoadable = () => <LoadablePackage />;

export default AdminLoadable;
