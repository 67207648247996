// @flow

import React, { Component } from "react";
import type { Node } from "react";
import { withStyles } from "@material-ui/core";
import ErrorBoundary from "../components/error.view";
import { styles } from "../styles/error.style";

export type Props = {
  children: Node,
  classes: { [key: string]: string }
};
export type State = {
  +hasError: boolean,
  +error: any,
  +info: any
};

class ErrorBoundaryContainer extends Component<Props, State> {
  state = { hasError: false, error: null, info: null };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  reload = () => {
    window.location.reload();
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ error: error.message, hasError: true, info });
  }

  render() {
    const { hasError } = this.state;
    const { children, ...rest } = this.props;
    if (hasError) {
      return <ErrorBoundary reload={this.reload} {...this.state} {...rest} />;
    }
    return children;
  }
}

export default withStyles(styles)(ErrorBoundaryContainer);
