// @flow
import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
// $FlowFixMe
import classNames from "classnames";
import DisconnectIcon from "@material-ui/icons/PowerSettingsNew";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import ListItem from "@material-ui/core/ListItem";
import TotalLogoCircle from "web/assets/images/total_logo_circle.png";
import TotalLogoText from "web/assets/images/total_logo_text.png";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Strings from "web/assets/strings";
import DashboardRouter from "../../router";
import HamburgerMenu from "../../../shared/components/hamburger.menu";
import Routes from "../../../shared/routes.def";
import ErrorBoundary from "../../../shared/containers/error.container";

import type {
  State as ContainerState,
  Props as ContainerProps,
} from "../containers/dashboard.container.layout";

type Props = ContainerState &
  ContainerProps & {
    toggleDrawer: () => void,
    adminView: boolean,
    toggleModal: () => void,
    logout: () => void,
  };

const DashboardLayout = ({
  classes,
  toggleDrawer,
  showDrawer,
  adminView,
  logout,
  toggleModal,
  openModal,
}: Props) => (
  <div className={classes.root}>
    <CssBaseline />
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !showDrawer && classes.drawerPaperClose,
        ),
      }}
      open={showDrawer}
    >
      <div className={classes.toolbar}>
        <img
          alt="total_logo"
          src={`${TotalLogoCircle}`}
          className={classes.logoCircle}
        />
        <Grow in={showDrawer}>
          <img
            alt="total_logo"
            src={`${TotalLogoText}`}
            className={classes.logoText}
          />
        </Grow>
      </div>
      <List>
        <ListItem
          className={classes.hamburgerContainer}
          button
          onClick={toggleDrawer}
        >
          <HamburgerMenu open={showDrawer} />
          {showDrawer && (
            <Typography className={classes.hamburgerText}>
              {Strings.global.closeMenu}
            </Typography>
          )}
        </ListItem>
      </List>
      <Fade in={showDrawer && !adminView}>
        <List className={classes.linkList}>
          <ListItem className={classes.listItemText} button key="dashboard">
            <Typography variant="body2" className={classes.listItemText}>
              {Strings.dashboard.title.toUpperCase()}
            </Typography>
          </ListItem>
          {Object.keys(Strings.links).map(key => (
            <ListItem className={classes.listItemText} button key={key}>
              <a
                href={Strings.links[key].link}
                className={`${classes.noDecoration}`}
              >
                <Typography variant="body2" className={classes.listItemText}>
                  {Strings.links[key].title.toUpperCase()}
                </Typography>
              </a>
            </ListItem>
          ))}
        </List>
      </Fade>
      <List className={classes.footerList}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Divider classes={{ root: classNames(classes.lightDivider) }} />
          </Grid>
          <Grid item xs={12}>
            <Link
              className={`${classes.link}`}
              to={adminView ? Routes.DASHBOARD : Routes.ADMIN}
            >
              <ListItem button key="admin">
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <DesktopMacIcon className={classes.white} />
                  </Grid>
                  <Grid item xs={10}>
                    {showDrawer && (
                      <Typography
                        variant="body2"
                        className={classes.listItemFooter}
                      >
                        {adminView
                          ? "Quitter le mode admin"
                          : "Vue administrateur"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            </Link>
          </Grid>
          <Grid item xs={10}>
            <Divider classes={{ root: classNames(classes.lightDivider) }} />
          </Grid>
          <Grid item xs={12}>
            <ListItem button key="disconnect" onClick={toggleModal}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={2}>
                  <DisconnectIcon className={classes.white} />
                </Grid>
                <Grid item xs={10}>
                  {showDrawer && (
                    <Typography
                      variant="body2"
                      className={classes.listItemFooter}
                    >
                      {Strings.global.disconnect}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </Drawer>
    <main className={classes.content}>
      <ErrorBoundary>{DashboardRouter}</ErrorBoundary>
    </main>
    <Dialog open={openModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {Strings.global.disconnect}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {Strings.global.wouldYouDisconnect}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="primary">
          {Strings.global.no}
        </Button>
        <Button onClick={logout} color="primary">
          {Strings.global.yes}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default DashboardLayout;
