// @flow
import React from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomTypography from "./custom.typography.component";

type Props = {
  classes: { [key: string]: string },
  title: string,
  boldTitle: string,
};

const SupportCard = ({ classes, title, boldTitle }: Props) => (
  <Paper className={classes.paper}>
    <Grid
      container
      className={classes.container}
      direction="column"
      justify="flex-end"
    >
      <Grid item className={classes.marginLeft}>
        <CustomTypography className={classes.title} size={19} variant="title">
          {title}
        </CustomTypography>
        <Grid container direction="row" alignItems="center">
          <CustomTypography
            bold
            capitalized="true"
            size={19}
            className={classes.boldTitle}
            variant="title"
          >
            {boldTitle}
          </CustomTypography>
          <ArrowForward className={classes.arrow} />
        </Grid>
      </Grid>
      <Divider light className={classes.divider} />
    </Grid>
  </Paper>
);

export default withStyles({
  paper: {
    backgroundImage:
      "linear-gradient(90deg, rgba(244,88,0,1) 0%, rgba(244,149,0,1) 50%)",
  },
  container: { minHeight: window.innerHeight * 0.21 },
  marginLeft: { marginLeft: 30 },
  title: { color: "#fff", lineHeight: 1, textTransform: "uppercase" },
  boldTitle: { color: "#fff", textAlign: "center", textTransform: "uppercase" },
  arrow: { margin: 5, color: "#fff" },
  divider: {
    backgroundColor: "#fff",
    marginBottom: 20,
    marginTop: 10,
    marginLeft: "15%",
    width: "70%",
  },
})(SupportCard);
