// @flow
import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import DashboardView from "../components/dashboard.view";
import dashboardStyle from "../styles/dashboard.style";

export type State = {};
export type Props = {
  classes: { [key: string]: string },
};

class Dashboard extends Component<Props, State> {
  componentDidMount() {
    console.log("isMounted");
  }

  render() {
    return <DashboardView {...this.props} />;
  }
}

export default withStyles(dashboardStyle)(Dashboard);
