// @flow
import type { $AxiosError } from "axios";
import { ACTIVATION_FAILED, ACTIVATION_REQUEST, ACTIVATION_SUCCESS } from "../../actions/constants";

import type {
  ActivationSuccessAction,
  ActivationFailedAction,
  ActivationRequestAction,
} from "./activate.action.types";

export const activationRequest = (
  IGG: string,
  mail: string,
  done: (error: ?$AxiosError<*, *>) => void,
): ActivationRequestAction => ({
  type: ACTIVATION_REQUEST,
  IGG,
  mail,
  done,
});

export const activationFailed = (error: $AxiosError<*, *>): ActivationFailedAction => ({
  type: ACTIVATION_FAILED,
  toast: true,
  error,
});

export const activationSucceeded = (): ActivationSuccessAction => ({
  type: ACTIVATION_SUCCESS,
  toast: true,
});
