// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import CountUp from "react-countup";
import CustomTypography from "../../shared/components/custom.typography.component";
import type {
  State as ContainerState,
  Props as ContainerProps,
  ImgType,
} from "../containers/kpi.container";
import GraphIcon from "../../assets/images/graph/objet-dynamique-vectoriel-copie.png";
import GraphIcon2x from "../../assets/images/graph/objet-dynamique-vectoriel-copie@2x.png";
import GraphIcon3x from "../../assets/images/graph/objet-dynamique-vectoriel-copie@3x.png";
import MoneyGraph from "../../assets/images/money_graph/money_graph.png";
import MoneyGraph2x from "../../assets/images/money_graph/money_graph@2x.png";
import MoneyGraph3x from "../../assets/images/money_graph/money_graph@3x.png";
import Customer from "../../assets/images/customer/customer.png";
import Customer2x from "../../assets/images/customer/customer@2x.png";
import Customer3x from "../../assets/images/customer/customer@3x.png";
import NewCustomer from "../../assets/images/new_customer/new_customer.png";
import NewCustomer2x from "../../assets/images/new_customer/new_customer@2x.png";
import NewCustomer3x from "../../assets/images/new_customer/new_customer@3x.png";
import PositifIcon from "../../assets/images/positif/positif-negatif-copie-2.png";
import PositifIcon2x from "../../assets/images/positif/positif-negatif-copie-2@2x.png";
import PositifIcon3x from "../../assets/images/positif/positif-negatif-copie-2@3x.png";

function getSrc(imgType: ?ImgType) {
  switch (imgType) {
  case "GRAPH":
    return GraphIcon;
  case "MONEY":
    return MoneyGraph;
  case "CUSTOMER":
    return Customer;
  case "NEW_CUSTOMER":
    return NewCustomer;
  case "CUSTOMER_LOST":
    return "";
  default:
    return GraphIcon;
  }
}

function getSrcSet(imgType: ?ImgType) {
  switch (imgType) {
  case "GRAPH":
    return `${GraphIcon2x}, ${GraphIcon3x}`;
  case "MONEY":
    return `${MoneyGraph2x}, ${MoneyGraph3x}`;
  case "CUSTOMER":
    return `${Customer2x}, ${Customer3x}`;
  case "NEW_CUSTOMER":
    return `${NewCustomer2x}, ${NewCustomer3x}`;
  case "CUSTOMER_LOST":
    return "";
  default:
    return `${GraphIcon2x}, ${GraphIcon3x}`;
  }
}

type Props = ContainerState & ContainerProps & {};
const KPIView = ({
  classes,
  titleNormal,
  titleBold,
  value,
  percentage,
  bottomText,
  imgType,
  suffix,
}: Props) => (
  <Grid item>
    <Grid item container alignItems="center" direction="row">
      <Grid item>
        <img
          alt="graph"
          src={getSrc(imgType)}
          srcSet={getSrcSet(imgType)}
          className={classes.iconSize}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={0}>
          <Grid
            item
            className={classes.headerGrid}
            justify="center"
            alignItems="baseline"
            container
            direction="row"
          >
            <CustomTypography size={15.5} className={classes.titleLeft}>
              {titleNormal}
            </CustomTypography>
            <CustomTypography size={15.5} bold>
              {titleBold}
            </CustomTypography>
          </Grid>
          <Grid item>
            <CountUp
              start={0}
              end={parseInt(value, 10)}
              delay={0}
              duration={2.5}
              suffix={suffix}
              separator=" "
              className={classes.value}
            />
          </Grid>
        </Grid>
        {percentage != null && (
          <Grid item container direction="row">
            <Grid item>
              <CustomTypography bold size={21} className={classes.grow}>
                {percentage}
                {" "}
                <img
                  alt="graph"
                  src={PositifIcon}
                  srcSet={`${PositifIcon2x}, ${PositifIcon3x}`}
                  className={classes.smallGrowthIcon}
                />
              </CustomTypography>
            </Grid>
          </Grid>
        )}
        {bottomText != null && (
          <Grid item container direction="row">
            {" "}
            <Grid item>
              <CustomTypography bold size={11} className={classes.bottomText}>
                {bottomText}
              </CustomTypography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);
export default KPIView;
