// @flow
import { createMuiTheme } from "@material-ui/core/styles";
import type { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: "#6c6c6c",
    },
    secondary: {
      main: "#ee1b3b",
    },
    error: {
      main: "#dd2b34",
    },
    text: {
      secondary: "rgb(228,2,45)",
      primary: "rgb(97,97,97)",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "HelveticaNeueNormal",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
    ].join(","),
  },
};
/*
    overriding theme cause Flow error because material-ui
    lib installed is 1.x.x vs 3.x.x installed
    $FlowFixMe
*/
export default createMuiTheme(theme);
