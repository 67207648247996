// @flow

import React from "react";
import type { ComponentType } from "react";
import { withState } from "recompose";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
  hovered: boolean,
  setHovered: (value: boolean) => void,
  classes: { [key: string]: string },
  Icon: ComponentType<*>,
  className?: ?string,
  title: string,
  onClick: () => void,
  empty?: ?boolean,
  type: string,
  loading?: ?boolean,
  disabled?: ?true,
  textClassName?: ?string,
  textHoverClassName?: ?string,
};

const style = () => ({
  buttonBackground: {
    background:
      "linear-gradient(90deg, rgba(190,18,49,1) 0%, rgba(228,3,45,1) 100%)",
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(190,18,49,1) 0%, rgba(228,3,45,1) 100%)",
      paddingLeft: "45px",
      paddingRight: "45px",
      opacity: 0.9,
    },
  },
  buttonOutlined: {
    backgroundColor: "#fff",
    border: "1px solid red",
    color: "red",
    "&:hover": {
      backgroundColor: "#fff",
      paddingLeft: "45px",
      paddingRight: "40px",
      opacity: 0.9,
    },
  },
  button: {
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "white",
    height: 20,
    shadow: "none",
    transition: "0.3s",
    borderRadius: "25px",
    textTransform: "none",
  },
  iconHovered: {
    marginLeft: 10,
    width: "20px",
    height: "20px",
  },
  iconNotHovered: {
    width: 0,
    height: 0,
  },
  icon: {
    transition: "width 0.2s ease-in-out",
    marginTop: "-4px",
  },
  iconRed: {
    color: "red",
    marginTop: "-5px",
  },
  text: {
    color: "white",
    textAlign: "center",
    marginTop: "-5px",
  },
  textRed: {
    background:
      "linear-gradient(90deg, rgba(190,18,49,1) 0%, rgba(228,3,45,1) 100%)",
    "-webkit-background-clip": "text",
    color: "transparent",
    marginTop: "-5px",
  },
  progress: {
    color: "#fff",
  },
});

const RoundedIconButton = ({
  hovered,
  setHovered,
  classes,
  Icon,
  empty,
  title,
  className,
  onClick,
  type,
  disabled,
  textClassName,
  textHoverClassName,
  loading,
}: Props) => (
  <Button
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    aria-label="Delete"
    type={type}
    onClick={onClick}
    disabled={disabled === true}
    className={`${classes.button} ${
      empty === true ? classes.buttonOutlined : classes.buttonBackground
    } ${className != null && className !== "" ? className : ""}`}
  >
    {/* $FlowFixMe - new variant not typed yet */}
    <Typography
      variant="overline"
      className={`${empty === true ? classes.textRed : classes.text} ${
        !hovered && textClassName != null && textClassName !== ""
          ? textClassName
          : ""
      } ${
        hovered && textHoverClassName != null && textHoverClassName !== ""
          ? textHoverClassName
          : ""
      }`}
    >
      {title}
    </Typography>
    {/* <Icon className={`${classes.icon} ${empty === true ? classes.iconRed : ""}`} /> */}
    {loading !== true && (
      <Icon
        className={`${classes.icon} ${empty === true ? classes.iconRed : ""} ${
          hovered ? classes.iconHovered : classes.iconNotHovered
        }`}
      />
    )}
    {loading === true && (
      <span className={`${classes.icon} ${classes.iconHovered}`}>
        <CircularProgress size={20} className={classes.progress} />
      </span>
    )}
  </Button>
);

RoundedIconButton.defaultProps = {
  empty: false,
  className: "",
  loading: false,
  disabled: false,
  textClassName: "",
  textHoverClassName: "",
};

export default withState("hovered", "setHovered", false)(
  withStyles(style)(RoundedIconButton),
);
