// @flow
// - Imports
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import type { SagaStore } from "core/configureStore";
import { hot } from "react-hot-loader";
import routes from "./routes";
import theme from "./assets/styles/main.style";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./shared/containers/error.container";
import StyledToastContainer from "./shared/components/styled.toast.container";

type Props = {
  config: {| store: SagaStore |},
};
const App = (props: Props) => {
  const { config } = props;
  return (
    <ErrorBoundary>
      <Provider store={config.store}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>{routes}</BrowserRouter>
          <StyledToastContainer />
        </MuiThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};
export default hot(module)(App);
