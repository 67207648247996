// @flow
import type { $AxiosError } from "axios";
import {
  PASSWORD_RESET_FAILED,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_UPDATE_FAILED,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_REQUEST,
  CHECK_PASSWORD_VALIDITY,
  PASSWORD_VALIDITY_SUCCESS,
  PASSWORD_VALIDITY_FAILED,
  PASSWORD_ASK_RESET_FAILED,
  PASSWORD_ASK_RESET_REQUEST,
  PASSWORD_ASK_RESET_SUCCESS,
} from "../../actions/constants";

import type {
  PasswordResetSuccessAction,
  PasswordResetFailedAction,
  PasswordResetRequestAction,
  PasswordUpdateSuccessAction,
  PasswordUpdateFailedAction,
  PasswordUpdateRequestAction,
  CheckPasswordValidityAction,
  PasswordValidationFailedAction,
  PasswordValidationSucceededAction,
  AskPasswordResetFailedAction,
  AskPasswordResetRequestAction,
  AskPasswordResetSuccessAction,
} from "./password.action.types";

/**
    Reset password actions
*/
export const resetPasswordSucceeded = (): PasswordResetSuccessAction => ({
  type: PASSWORD_RESET_SUCCESS,
});

export const resetPasswordRequest = (
  token: string,
  password: string,
  passwordConfirmation: string,
  done: (error: ?$AxiosError<*, *>) => void,
): PasswordResetRequestAction => ({
  type: PASSWORD_RESET_REQUEST,
  password,
  passwordConfirmation,
  token,
  done,
});

export const resetPasswordFailed = (error: $AxiosError<*, *>): PasswordResetFailedAction => ({
  type: PASSWORD_RESET_FAILED,
  toast: true,
  error,
});

/**
    Password update actions
*/
export const updatePasswordSucceeded = (): PasswordUpdateSuccessAction => ({
  type: PASSWORD_UPDATE_SUCCESS,
  toast: true,
});

export const updatePasswordRequest = (
  userId: number,
  oldPassword: string,
  password: string,
  passwordConfirmation: string,
  done: (error: ?string) => void,
): PasswordUpdateRequestAction => ({
  type: PASSWORD_UPDATE_REQUEST,
  userId,
  oldPassword,
  password,
  passwordConfirmation,
  done,
});

export const updatePasswordFailed = (): PasswordUpdateFailedAction => ({
  type: PASSWORD_UPDATE_FAILED,
  toast: true,
});

/**
  Password check validity
*/
export const checkPasswordValidity = (
  userId: number,
  done: (error: ?$AxiosError<*, *>) => void,
): CheckPasswordValidityAction => ({
  type: CHECK_PASSWORD_VALIDITY,
  userId,
  done,
});

export const passwordValidationSucceeded = (): PasswordValidationSucceededAction => ({
  type: PASSWORD_VALIDITY_SUCCESS,
});

export const passwordValidationFailed = (
  error: $AxiosError<*, *>,
): PasswordValidationFailedAction => ({
  type: PASSWORD_VALIDITY_FAILED,
  error,
});

/**
  Password reset ask
*/
export const askResetPassword = (
  igg: string,
  email: string,
  done: (error: ?$AxiosError<*, *>) => void,
): AskPasswordResetRequestAction => ({
  type: PASSWORD_ASK_RESET_REQUEST,
  igg,
  email,
  done,
});

export const askPasswordResetSucceeded = (): AskPasswordResetSuccessAction => ({
  type: PASSWORD_ASK_RESET_SUCCESS,
  toast: true,
});

export const askPasswordResetFailed = (error: $AxiosError<*, *>): AskPasswordResetFailedAction => ({
  type: PASSWORD_ASK_RESET_FAILED,
  toast: true,
  error,
});
