// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import type { Saga, Effect } from "redux-saga";
import type { AuthenticationRequestAction } from "../actions/auth.action.types";
import { authFailed, authSucceeded } from "../actions/auth.action";
import AuthApi from "../../api/auth.api";
import { AUTH_REQUEST } from "../../actions/constants";
import { initSessionSaga } from "../../users/sagas/session.saga";
import { initSession } from "../../users/actions/session.action";
/**
 * Handle auth process
 *
 * @param {AuthenticationRequestAction} action
 * @returns {Saga<void>}
 */
export function* workerSaga(action: AuthenticationRequestAction): Saga<void> {
  try {
    yield call(AuthApi.login, action.username, action.password);
    yield call(initSessionSaga, initSession(action.done));
    yield put(authSucceeded());
    // Trigger callback needed to return an error to reduxForm
    action.done();
  } catch (error) {
    yield put(authFailed(error));
    // Trigger callback needed to return an error to reduxForm
    action.done(error);
  }
}

/**
 * Watching for AUTH_REQUEST EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export default function* watcherSaga(): Iterable<Effect> {
  yield takeLatest(AUTH_REQUEST, workerSaga);
}
