// @flow
import axios from "axios";

axios.defaults.withCredentials = true;
export const api = axios.create({
  baseURL: "https://total-gr-pilot.loyaltyexpert.net/api",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});
