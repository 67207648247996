// @flow
import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import type {
  State as ContainerState,
  Props as ContainerProps,
} from "../containers/fuel.station.container";

type Props = ContainerState &
  ContainerProps & {
    volumeValue: string,
    volumeGrowth: string,
    spreadValue: string,
    spreadGrowth: string,
  };
const FuelStationView = ({
  classes,
  imgSrc,
  imgSrcSet,
  volumeValue,
  volumeGrowth,
  spreadValue,
  spreadGrowth,
}: Props) => (
  <Fragment>
    <Grid item>
      <img
        alt="logo-img"
        src={imgSrc}
        srcSet={imgSrcSet}
        className={classes.img}
      />
    </Grid>
    <Grid item>
      <Typography className={classes.labels}>
        {volumeValue}
        <span className={classes.redLabels}>{volumeGrowth}</span>
      </Typography>
    </Grid>
    <Grid item>
      <Typography className={classes.labels}>
        {spreadValue}
        <span className={classes.greenLabels}>{spreadGrowth}</span>
      </Typography>
    </Grid>
  </Fragment>
);

export default FuelStationView;
