// @flow
import React from "react";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { Field } from "redux-form";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import LinkButton from "web/shared/components/link.component";
import Typography from "@material-ui/core/Typography";
import Strings from "web/assets/strings";
import { Link } from "react-router-dom";
import type {
  Props as ContainerProps,
  State as ContainerState,
} from "../containers/auth.container";
import RFTextField from "./textfield.component";
import Routes from "../../shared/routes.def";
import LoadingButton from "../../shared/components/loading.button";

type Props = ContainerProps &
  ContainerState & {
    togglePasswordVisibility: () => void,
    login: () => void,
  };

export default (props: Props) => {
  const {
    classes, handleSubmit, togglePasswordVisibility, showPassword, login, loading,
  } = props;
  return (
    <Fade in>
      <form onSubmit={handleSubmit(login)} className={`${classes.fullHeight}`}>
        <Grid container className={`${classes.fieldsContainer}`} justify="center">
          <Grid item>
            {/* $FlowFixMe */}
            <Typography component="h2" variant="h5" gutterBottom className={`${classes.title}`}>
              {Strings.auth.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              className={`${classes.authField}`}
              name="IGG"
              icon={<PersonIcon className={classes.iconColor} />}
              component={RFTextField}
              placeholder={Strings.auth.iggPlaceholder}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Field
              className={`${classes.authField}`}
              name="password"
              component={RFTextField}
              icon={<LockIcon className={classes.iconColor} />}
              type={showPassword ? "text" : "password"}
              placeholder={Strings.auth.pwdPlaceholder}
              variant="outlined"
              required
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.endAdornment}>
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              style={{ height: 100 }}
              direction="column"
              alignItems="flex-end"
              justify="flex-end"
            >
              <LinkButton variant="text" component="span" className={`${classes.buttonLink}`}>
                <Link className={`${classes.link}`} to={Routes.PASSWORD_FORGOTTEN}>
                  <Typography variant="caption" gutterBottom>
                    {Strings.auth.forgottenPassword}
                  </Typography>
                </Link>
              </LinkButton>
              <LinkButton variant="text" component="span" className={`${classes.buttonLink}`}>
                <Link className={`${classes.link}`} to={Routes.FIRST_CONNECT}>
                  <Typography variant="caption" gutterBottom>
                    {Strings.auth.firstConnection}
                  </Typography>
                </Link>
              </LinkButton>
            </Grid>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              className={`${classes.connectButton}`}
              text={Strings.auth.connect}
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};
