// @flow
import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthenticationLayout from "./authentication/layouts/auth.layout";
import { withAuthentication } from "./shared/hoc/auth.hoc";
import DashboardLayout from "./dashboard/layouts/containers/dashboard.container.layout";
import Routes from "./shared/routes.def";

export default (
  <Switch>
    <Route
      path={Routes.DASHBOARD}
      component={withAuthentication(DashboardLayout)}
    />
    <Route path={Routes.AUTH} component={AuthenticationLayout} />
    <Redirect to={Routes.AUTH} />
  </Switch>
);
