// @flow

export default () => ({
  growthIcon: { height: "10px", margin: 2 },
  test: {
    width: "100%",
    height: "100%",
  },
  icon: {
    height: "6vh",
    marginRight: 15,
  },
  value: {
    marginTop: 30,
  },
  growth: {
    marginTop: -10,
  },
});
