// @flow
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
// import type { State as GlobalState } from "core/reducer";
import FuelStationView from "../components/fuel.station.view";
import { FuelStationStyle } from "../styles/fuel.station.style";

export type Props = {
  classes: { [key: string]: string },
  /**
   * Img to show
   *
   * @type {string}
   */
  imgSrc: string,
  /**
   * Img set to provide 2 imgs for bigger screens
   * ( @2x, @3x)
   * @type {string}
   */
  imgSrcSet: string,
};
export type State = {};
class FuelStationType extends Component<Props, State> {
  componentDidMount() {
    console.log("FuelStationtype");
  }

  render() {
    return (
      <FuelStationView
        {...this.props}
        volumeValue="38 592"
        volumeGrowth=" - 0,3%"
        spreadValue="100%"
        spreadGrowth=" + 0,3%"
      />
    );
  }
}
const mapStateToProps = (/* state: GlobalState */) => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(FuelStationStyle)(FuelStationType));
