// @flow
import type { Theme } from "@material-ui/core";

export default (theme: Theme) => ({
  titleLeft: { marginLeft: 10, marginRight: 5 },
  headerGrid: { height: 10, marginBottom: "1.5vh" },
  iconSize: { height: "3vw" },
  smallGrowthIcon: { height: "1.5vh", marginBottom: 2 },
  bottomText: { marginLeft: 10, maxWidth: 60 },
  value: {
    fontSize: "22px",
    "font-family": "HelveticaNeueNormal",
    color: "#6f6f6e",
    marginLeft: 10,
    lineHeight: 1,
    [theme.breakpoints.up("xl")]: {
      fontSize: "27px",
    },
  },
  grow: {
    fontSize: "19.5px",
    marginLeft: 10,
    lineHeight: 1,
  },
});
