// @flow
import React, { Component } from "react";
import { SubmissionError, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import type { State as GlobalState } from "core/reducer";
import { askResetPassword } from "core/authentication/actions/password.action";
import type { RouterHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import type { FormProps, Form } from "../../shared/custom-types-definitions/redux-form";
import ForgottenPasswordView from "../components/forgotten.password.view";
import { authStyle } from "../styles/auth.style";
import Routes from "../../shared/routes.def";

export type Props = {
  askResetPassword: typeof askResetPassword,
  resetPasswordForm: Form,
  classes: { [key: string]: string },
  history: RouterHistory,
} & FormProps;

export type State = {
  loading: boolean,
};

class ForgottenPasswordContainer extends Component<Props, State> {
  state = {
    loading: false,
  };

  onResetPasswordClick = () => new Promise((resolve, reject) => {
    const { resetPasswordForm, askResetPassword, history } = this.props;
    if (resetPasswordForm.values) {
      this.setState(() => ({ loading: true }));
      askResetPassword(resetPasswordForm.values.IGG, resetPasswordForm.values.mail, (error) => {
        if (error != null) {
          this.setState(() => ({ loading: false }));
          reject(new SubmissionError({ _error: error }));
        } else {
          this.setState(() => ({ loading: false }));
          setTimeout(() => {
            history.replace(Routes.AUTH);
          }, 300);
          resolve();
        }
      });
    } else {
      reject(new SubmissionError({ _error: "ERROR" }));
    }
  });

  render() {
    const { resetPasswordForm, ...rest } = this.props;
    return (
      <ForgottenPasswordView
        onResetPasswordClick={this.onResetPasswordClick}
        {...rest}
        {...this.state}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  resetPasswordForm: state.form.resetPasswordForm,
});

const mapDispatchToProps = {
  askResetPassword,
};

const ForgottenPasswordContainerForm = reduxForm({
  form: "resetPasswordForm",
})(ForgottenPasswordContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(authStyle)(withRouter(ForgottenPasswordContainerForm)));
