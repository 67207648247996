// @flow
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import type { Actions } from "./actions";

import authReducer, {
  initialState as AuthInitialState,
} from "./authentication/reducers/auth.reducer";
import userReducer, {
  initialState as UserInitialState,
} from "./users/reducers/user.reducer";

const rootReducer = {
  form: formReducer,
  auth: authReducer,
  user: userReducer,
};

export type Reducers = typeof rootReducer;
type $ExtractFunctionReturn = <V>(v: (...args: any) => V) => V;
export type State = $ObjMap<Reducers, $ExtractFunctionReturn>;

export const initialState: State = {
  auth: AuthInitialState,
  form: {},
  user: UserInitialState,
};
const appReducer = combineReducers(rootReducer);

export default (state: State, action: Actions) => {
  if (action.type === "SESSION_LOGOUT_SUCCESS") {
    return appReducer(initialState, action);
  }
  return appReducer(state, action);
};
