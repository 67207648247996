// @flow
import type { Store as ReduxStore } from "redux";
import type { State as GlobalState } from "../reducer";
import type { Actions } from "../actions";
import errorsMessages from "../strings/errors.strings.json";
import successMessages from "../strings/success.strings.json";

export type Store = ReduxStore<GlobalState, Actions>;

export default (
  callback: (msg: string, type: "ERROR" | "SUCCESS") => void,
) => () => (next: (action: Actions) => void) => (action: Actions) => {
  if (action.toast === true && action.type.includes("FAILED")) {
    let message = errorsMessages.DEFAULT;
    if (
      action.error
      && action.error.response != null
      && errorsMessages[action.type] != null
    ) {
      message = errorsMessages[action.type][action.error.response.status];
    }
    callback(message, "ERROR");
  } else if (action.toast === true && action.type.includes("SUCCESS")) {
    if (successMessages[action.type] != null) {
      callback(successMessages[action.type], "SUCCESS");
    }
  }
  next(action);
};
