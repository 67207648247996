// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import type { Saga, Effect } from "redux-saga";
import type {
  PasswordResetRequestAction,
  PasswordUpdateRequestAction,
  CheckPasswordValidityAction,
  AskPasswordResetRequestAction,
} from "../actions/password.action.types";
import {
  resetPasswordSucceeded,
  resetPasswordFailed,
  updatePasswordSucceeded,
  updatePasswordFailed,
  passwordValidationFailed,
  passwordValidationSucceeded,
  askPasswordResetSucceeded,
  askPasswordResetFailed,
} from "../actions/password.action";
import AuthApi from "../../api/auth.api";
import {
  PASSWORD_RESET_REQUEST,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_ASK_RESET_REQUEST,
} from "../../actions/constants";

/**
 * Handle auth process
 *
 * @param {PasswordRequestAction} action
 * @returns {Saga<void>}
 */
export function* passwordResetSaga(action: PasswordResetRequestAction): Saga<void> {
  try {
    yield call(AuthApi.resetPassword, action.token, action.password, action.passwordConfirmation);
    yield put(resetPasswordSucceeded());
    action.done();
  } catch (error) {
    yield put(resetPasswordFailed(error));
    action.done(error);
  }
}

/**
 * Handle auth process
 *
 * @param {PasswordUpdateAction} action
 * @returns {Saga<void>}
 */
export function* passwordUpdateSaga(action: PasswordUpdateRequestAction): Saga<void> {
  try {
    yield call(
      AuthApi.updatePassword,
      action.userId,
      action.oldPassword,
      action.password,
      action.passwordConfirmation,
    );
    yield put(updatePasswordSucceeded());
    action.done();
  } catch (error) {
    yield put(updatePasswordFailed());
    action.done(error);
  }
}

/**
 * Ask for password reset
 *
 * @param {PasswordUpdateAction} action
 * @returns {Saga<void>}
 */
export function* askPasswordResetSaga(action: AskPasswordResetRequestAction): Saga<void> {
  try {
    yield call(AuthApi.askResetPassword, action.igg, action.email);
    yield put(askPasswordResetSucceeded());
    action.done();
  } catch (error) {
    yield put(askPasswordResetFailed(error));
    action.done(error);
  }
}

/**
 * Watching for PASSWORD_ASK_RESET_REQUEST EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export function* askPasswordResetWatcherSaga(): Iterable<Effect> {
  yield takeLatest(PASSWORD_ASK_RESET_REQUEST, askPasswordResetSaga);
}

/**
 * Watching for PASSWORD_REQUEST EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export function* passwordResetWatcherSaga(): Iterable<Effect> {
  yield takeLatest(PASSWORD_RESET_REQUEST, passwordResetSaga);
}

/**
 * Watching for PASSWORD_REQUEST EVENT
 *
 * @export
 * @returns {Iterable<Effect>}
 */
export function* passwordUpdateWatcherSaga(): Iterable<Effect> {
  yield takeLatest(PASSWORD_UPDATE_REQUEST, passwordUpdateSaga);
}

export function* checkPasswordValiditySaga(action: CheckPasswordValidityAction): Saga<void> {
  try {
    yield call(AuthApi.checkPasswordValidity, action.userId);
    yield put(passwordValidationSucceeded());
  } catch (error) {
    yield put(passwordValidationFailed(error));
    action.done(error);
  }
}
